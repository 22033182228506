import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  deleteUser,
} from 'firebase/auth';
import {
  collection,
  doc,
  getDoc,
  setDoc,
  DocumentData,
  initializeFirestore,
  updateDoc,
  Timestamp,
} from 'firebase/firestore';
// @types
import { ActionMap, AuthState, AuthUser, FirebaseContextType } from '../@types/auth';
//
import { FIREBASE_API } from '../config/config';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
} from 'firebase/storage';
import {
  CustomClaims,
  InviteDataClientArgs,
  SignUpArgs,
  UpdateUserInviteArgs,
  UpdateUserSignUpArgs,
  UserCtx,
  UserDb,
} from 'src/guards/admin/types';
import { getPermissionsFromPcodes } from 'src/guards/admin/utility';
import { isAgentData, isStaffData, isPromoterData } from 'src/guards/admin/guards';
/* import { useSnackbar } from 'notistack'; */
// ----------------------------------------------------------------------
var firebasecredentials = FIREBASE_API.prod;
if (
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_FIREBASE_PROJECT === 'instadrinkuat'
) {
  firebasecredentials = FIREBASE_API.prod;
} else if (process.env.REACT_APP_FIREBASE_PROJECT === 'instadrinkuat') {
  firebasecredentials = FIREBASE_API.hom;
} else if (process.env.REACT_APP_FIREBASE_PROJECT === 'instadrink-47f0f') {
  firebasecredentials = FIREBASE_API.dev;
}
const firebaseApp = initializeApp(firebasecredentials);
export const AUTH = getAuth(firebaseApp);
export const DB = initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true });
//export const DB = getFirestore(firebaseApp);
const STORAGE = getStorage(firebaseApp);
const FUNCTIONS_REGION = 'europe-west1';
export const FUNCTIONS = getFunctions(firebaseApp, FUNCTIONS_REGION);
//connectFunctionsEmulator(FUNCTIONS, "127.0.0.1", 5001);
const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};
enum Types {
  Initial = 'INITIALISE',
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};
type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];
const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  return state;
};
const AuthContext = createContext<FirebaseContextType | null>(null);
// ----------------------------------------------------------------------
type AuthProviderProps = {
  children: ReactNode;
};
export interface UsersData {
  allIds: string[];
  userIds: string[];
  agentUserIds: string[];
  agentsIds: { [key: string]: boolean };
  claimsData: CustomClaims[];
  sortedUsers: CustomClaims[];
  sortedAgents: CustomClaims[];
}
function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [profile, setProfile] = useState<UserDb | undefined>();
  const [claims, setClaims] = useState<CustomClaims>();
  const [isLoading, setIsLoading] = useState(true);
  const onChangeUser = (user: DocumentData) => {
    const UserDb = user as UserDb;
    setProfile(UserDb);
  };
  useEffect(
    () =>
      onAuthStateChanged(AUTH, async (user) => {
        //console.log('user', user);
        if (user) {
          const idTokenResult = await user.getIdTokenResult();
          //const api = parseFloat(idTokenResult?.claims?.api as string ?? '0') || 0;
          const claims = idTokenResult.claims as unknown as CustomClaims;
          console.log('claims', claims);

          const api = claims.api || 0;
          let custClaim = idTokenResult.claims as unknown as CustomClaims;
          setClaims(custClaim);
          const userRef = doc(DB, 'users', user.uid);
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {
            const userData = docSnap.data() as UserDb;
            setProfile(userData);
          }
          console.log('api version', api);

          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: true, user },
          });
        } else {
          console.log('No user');
          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: false, user: null },
          });
        }
        setIsLoading(false);
      }),
    [dispatch, setProfile, setIsLoading]
  );
  const getClaims = async ({ orgId }: { orgId: string }): Promise<UsersData> => {
    try {
      console.log('orgId', orgId);
      if (!orgId) throw new Error('Org Id is required.');
      console.log(`Loading claims for ${orgId} users...`);
      const getClaims = httpsCallable(FUNCTIONS, 'getCusomClaimsData');
      const result = (await getClaims({ orgId })) as { data: UsersData };
      console.log(`Successfully loaded claims for ${result.data.allIds.length} users`);
      return result.data;
    } catch (error) {
      console.error(`Error loading claims for ${orgId} users : ${error}`);
      throw new Error(error);
    }
  };
  const getClaimsAmb = async (): Promise<UsersData> => {
    try {
      console.log(`Loading claims for ambassador invites...`);
      const getClaimsAmb = httpsCallable(FUNCTIONS, 'getCusomClaimsDataAmb');
      const result = (await getClaimsAmb()) as { data: UsersData };
      console.log(result.data);
      console.log(`Successfully loaded claims for ${result.data.allIds.length} users`);
      return result.data;
    } catch (error) {
      console.error(`Error loading claims for ambassador invites...`);
      throw new Error(error);
    }
  };
  // const login = (email: string, password: string) =>
  //   signInWithEmailAndPassword(AUTH, email, password);

  const login = async (email: string, password: string) => {
    const userCredential: any = await signInWithEmailAndPassword(AUTH, email, password);
    const currentUser = userCredential.user;

    const userdocRef = doc(DB, 'users', currentUser.uid);

    await updateDoc(userdocRef, {
      lastLoggedIn: Timestamp.fromDate(new Date()),
    });
    return currentUser;
  };
  const resetPassword = (email: string) => sendPasswordResetEmail(AUTH, email);
  const register = (email: string, password: string, firstName: string, lastName: string) =>
    createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
      const userRef = doc(collection(DB, 'users'), res.user?.uid);
      await setDoc(userRef, {
        uid: res.user?.uid,
        email,
        displayName: `${firstName} ${lastName}`,
        role: 'Owner',
        firstName: firstName,
        lastName: lastName,
        isNewUser: true,
      });
    });
  /*  const createUser = async (newUser: NewUser) =>
   {
     try
     {
       // Call the Cloud Function to create a new user with custom claims and add it to Firestore
       const createUser = httpsCallable(FUNCTIONS, 'createUser');
       const result = await createUser({ newUser });
       console.log(result);
       if (result.data) console.log('Successfully created new user:', newUser.email);
       else console.log('Failed to create new user:', newUser.email);
     } catch (error)
     {
       console.error('Error creating new user:', error);
       throw new Error(error);
     }
   }; */
  /*   const createOwner = async (newOwner: NewSignUp) =>
    {
      try
      {
        if (!newOwner.password)
        {
          throw new Error('Password is required.');
        }
        const createOwner = httpsCallable(FUNCTIONS, 'createOwner');
        const result = await createOwner({ newOwner });
        if (result?.data === true)
        {
          console.log('Successfully created new owner:', newOwner.email);
          signInWithEmailAndPassword(AUTH, newOwner.email, newOwner.password);
        } else console.log('Failed to create new owner:', newOwner.email);
      } catch (error)
      {
        console.error('Error creating new owner:', error);
        throw new Error(error);
      }
    }; */

  const signupUserCallable = async (signUpArgs: SignUpArgs): Promise<boolean> => {
    const { firstName, lastName, email, division, role, lang } = signUpArgs;
    const language = lang || 'en';
    if (!firstName || !lastName || !division || !role || !language)
      throw new Error('Missing fields');
    try {
      const signupUser = httpsCallable(FUNCTIONS, 'signupUser');
      const result = await signupUser(signUpArgs);
      if (result?.data === true) {
        return true;
        // signInWithEmailAndPassword(AUTH, email, password);
      } else console.log('Failed to create new user:', email);
    } catch (error) {
      throw new Error(error);
    }
    return false;
  };

  const requestInvitationCallable = async (
    inviteDataClientArgs: InviteDataClientArgs
  ): Promise<boolean> => {
    const { email, firstName, lastName, invitedRole, invitedDivision, lang } = inviteDataClientArgs;
    const language = lang || 'en';
    if (!firstName || !lastName || !invitedRole || !invitedDivision || !language)
      throw new Error('Missing fields');
    try {
      const requestInvitation = httpsCallable(FUNCTIONS, 'requestInvitation');
      const result = await requestInvitation(inviteDataClientArgs);
      if (result?.data === true) {
        return true;
        // signInWithEmailAndPassword(AUTH, email, password);
      } else console.log('Failed to request invite user:', email);
    } catch (error) {
      throw new Error(error);
    }
    return false;
  };

  const acceptInviteUserCallable = async (invitationId: string): Promise<boolean> => {
    if (!invitationId) throw new Error('Missing fields');
    try {
      const acceptInviteUser = httpsCallable(FUNCTIONS, 'acceptInviteUser');
      const result = await acceptInviteUser(invitationId);
      if (result?.data === true) {
        return true;
        // signInWithEmailAndPassword(AUTH, email, password);
      } else console.log('Failed to accept invite user with invitation:', invitationId);
    } catch (error) {
      if (error.code && error.message) throw new Error(`${error.code} : ${error.message}`);
      else if (!error.code && error.message) throw new Error(error.message);
      else if (error.code && !error.message) throw new Error(error.code);
      else throw new Error(error);
    }
    return false;
  };

  const updateInvitedUserCallable = async (
    updateUserInviteArgs: UpdateUserInviteArgs
  ): Promise<boolean> => {
    const { firstName, lastName, password, lang } = updateUserInviteArgs;
    const language = lang || 'en';
    if (!firstName || !lastName || !password || !language) throw new Error('Missing fields');
    try {
      const updateInvitedUser = httpsCallable(FUNCTIONS, 'updateInvitedUser');
      const result = await updateInvitedUser(updateUserInviteArgs);
      if (result?.data === true) {
        return true;
        // signInWithEmailAndPassword(AUTH, email, password);
      } else console.log('Failed to request invite user:', firstName);
    } catch (error) {
      throw new Error(error);
    }
    return false;
  };

  const rejectInviteUserCallable = async (invitationId: string): Promise<boolean> => {
    if (!invitationId) throw new Error('Missing fields');
    try {
      const rejectInviteUser = httpsCallable(FUNCTIONS, 'rejectInviteUser');
      const result = await rejectInviteUser(invitationId);
      if (result?.data === true) {
        return true;
        // signInWithEmailAndPassword(AUTH, email, password);
      } else console.log('Failed to reject invite user with invitation:', invitationId);
    } catch (error) {
      if (error.code && error.message) throw new Error(`${error.code} : ${error.message}`);
      else if (!error.code && error.message) throw new Error(error.message);
      else if (error.code && !error.message) throw new Error(error.code);
      else throw new Error(error);
    }
    return false;
  };

  const cancelInviteUserCallable = async (invitationId: string): Promise<boolean> => {
    if (!invitationId) throw new Error('Missing fields');
    try {
      const cancelInviteUser = httpsCallable(FUNCTIONS, 'acceptInviteUser');
      const result = await cancelInviteUser(invitationId);
      if (result?.data === true) {
        return true;
        // signInWithEmailAndPassword(AUTH, email, password);
      } else console.log('Failed to cancel invite user with invitation:', invitationId);
    } catch (error) {
      throw new Error(error);
    }
    return false;
  };

  const finishOnboardingCallable = async (newUserId: string): Promise<boolean> => {
    if (!newUserId) throw new Error('Missing fields');
    try {
      const finishOnboarding = httpsCallable(FUNCTIONS, 'finishOnboarding');
      const result = await finishOnboarding(newUserId);
      if (result?.data === true) {
        return true;
        // signInWithEmailAndPassword(AUTH, email, password);
      } else console.log('Failed to finish onboarding:', newUserId);
    } catch (error) {
      throw new Error(error);
    }
    return false;
  };

  const updateSignupUserCallable = async (
    updateUserSignUpArgs: UpdateUserSignUpArgs
  ): Promise<boolean> => {
    try {
      console.log('Verifying account...');
      const updateUser = httpsCallable(FUNCTIONS, 'updateSignupUser');
      const result = await updateUser(updateUserSignUpArgs);
      console.log(result);
      console.log(result?.data);
      if (result?.data === true) {
        console.log('Successfully verified email:');
        return true;
      } else console.log('Failed to verify email:');
    } catch (error) {
      console.error('Error verify email:', error);
      throw new Error(error);
    }
    return false;
  };

  const logout = () => signOut(AUTH);

  const unregistration = async () => {
    try {
      await deleteUser(AUTH.currentUser as any);
      console.log('AUTH', AUTH.currentUser);
    } catch (error) {
      console.log('error deleting user: ', error);
    }
  };
  const permissions = getPermissionsFromPcodes(claims?.pcodes || []);

  let orgId: string | undefined,
    ownerId: string | undefined,
    orgType: string | undefined,
    isCustom: boolean | undefined,
    roleId: string | null | undefined = undefined;

  if (isStaffData(claims)) {
    ({ orgId, ownerId, orgType, isCustom, roleId } = claims);
  }

  let invitationId: string | null | undefined,
    inviterId: string | null | undefined,
    isInvite: boolean | undefined = undefined;

  if (isAgentData(claims) || isPromoterData(claims)) {
    ({ invitationId, inviterId, isInvite } = claims);
  }

  let ancestors: string[] | undefined,
    mainAncestorId: string | null | undefined,
    level: number | undefined,
    orgs: string[] | null | undefined;

  if (isPromoterData(claims)) {
    ({ ancestors, mainAncestorId, level, orgs } = claims);
  }

  let user: UserCtx = {
    dataType: 'Ctx',
    api: claims?.api || 0,
    // InfoUserData
    uid: claims?.user_id || state?.user?.uid || profile?.uid || '',
    firstName: claims?.firstName || profile?.firstName || 'firstName',
    lastName: claims?.lastName || profile?.lastName || 'lastName',
    displayName: claims?.displayName || profile?.displayName || '',
    email: claims?.email || state?.user?.email,
    lang: claims?.lang || 'fr',
    // DivisionUserData
    type: claims?.type,
    code: claims?.code,
    division: claims?.division,
    role: claims?.role,
    /// DivisionUserData staff
    orgId,
    ownerId,
    orgType,
    isCustom,
    roleId,
    /// DivisionUserData invited promoters or agent
    isInvite,
    invitationId,
    inviterId: inviterId,
    /// DivisionUserData promoters
    ancestors: profile?.ancestors || [],
    mainAncestorId,
    level,
    orgs,
    // ExtraUserData
    photoURL: profile?.photoURL || '',
    phoneNumber: profile?.phoneNumber || '',
    address: profile?.address || '',
    city: profile?.city || '',
    state: profile?.state || '',
    zipCode: profile?.zipCode || '',
    country: profile?.country || '',
    about: profile?.about || '',
    creationTime: profile?.creationTime || '',
    // OnboardingUserData
    onBoardingSteps: claims?.onBoardingSteps || [],
    isNewUser: profile?.isNewUser || false,
    // AccessUserData
    access: claims?.access || { access_insta: false, access_insta_pro: false },
    pcodes: claims?.pcodes || [],
    // AccountUserData
    email_verified: claims?.email_verified || false,
    isPaymentVerified: claims?.isPaymentVerified || false,
    accountDisabled: claims?.accountDisabled || false,
    // PermissionUserData
    permissions,
    // MoreUserData
    stripeConnect: profile?.stripeConnect,
    referredevents: profile?.referredevents || [],
    // DefaultClaims
    user_id: claims?.user_id || state?.user?.uid || profile?.uid || '',
  } as UserCtx;

  console.log(user);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user,
        isLoading,
        login,
        register,
        logout,
        resetPassword,
        getClaims,
        getClaimsAmb,
        onChangeUser,
        signupUserCallable,
        updateSignupUserCallable,
        requestInvitationCallable,
        acceptInviteUserCallable,
        updateInvitedUserCallable,
        rejectInviteUserCallable,
        cancelInviteUserCallable,
        finishOnboardingCallable,
        isSignInWithEmailLink,
        signInWithEmailLink,
        unregistration,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
/* function uploadFileFirebase(file: File, path: string) {
  const storageRef = ref(STORAGE, path);
  return uploadBytesResumable(storageRef, file);
} */
const uploadFileFirebase = async (file: File, path: string) => {
  const filename = new Date().getTime() + file.name;
  const storageRef = ref(STORAGE, path + filename);
  const uploadTask = uploadBytesResumable(storageRef, file);
  await uploadTask;
  const fileUrl = await getDownloadURL(uploadTask.snapshot.ref);
  return fileUrl;
};
const deleteFileFirebase = async (path: string) => {
  const storageRef = ref(STORAGE, path);
  return deleteObject(storageRef);
};
export { AuthContext, AuthProvider, uploadFileFirebase, deleteFileFirebase };
