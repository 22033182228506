// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to instadrinkpro@gmail.com.

// ----------------------------------------------------------------------

const fr = {
  demo: {
    title: `France`,
    introduction: `Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.`,
  },
  docs: {
    hi: `Bonjour`,
    description: `Besoin d'aide? \n Consultez notre documentation.`,
    documentation: `documentation`,
  },
  app: `application`,
  user: `utilisateur`,
  list: `liste`,
  edit: `éditer`,
  shop: `boutique`,
  blog: `blog`,
  post: `poste`,
  mail: `e-mail`,
  chat: `discuter`,
  cards: `cartes`,
  posts: `des postes`,
  create: `créer`,
  kanban: `kanban`,
  general: `général`,
  banking: `bancaire`,
  booking: `réservation`,
  profile: `profil`,
  account: `compte`,
  product: `produit`,
  invoice: `facture`,
  details: `Détails`,
  checkout: `vérifier`,
  calendar: `calendrier`,
  analytics: `analytique`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `niveau menu 1`,
  menu_level_2: `niveau menu 2`,
  menu_level_3: `niveau menu 3`,
  item_disabled: `élément désactivé`,
  item_label: `étiquette de l'article`,
  item_caption: `légende de l'article`,
  description: "Description de l'événement",
  other_cases: `autres cas`,
  item_by_roles: `élément par rôles`,
  only_admin_can_see_this_item: `seul l'administrateur peut voir cet élément`,
  draft: 'Brouillon',
  ready: 'Prêt',
  published: 'publié',
  attendees: 'participants',
  export: 'exporter',
  create_event: 'Créer un événement',
  create_org: 'Créer une organisation',
  sales: 'ventes',
  set_banking_message: '',
  label_Rows_Per_Page: 'Lignes par page',
  created_At: 'Créé à',
  location: 'Emplacement',
  title: 'titre',
  events: 'Événements',
  archived: 'Archivé',
  canceled: 'Annulé',
  Start_Date: "Date d’ouverture de l'événement",
  End_Date: "Date de clôture de l'événement",
  organisation: "Nom de l'organisation",
  organisations: 'Organisations',
  published_events: 'Événements publiés ',
  Add_event: 'Ajouter un événement',
  event_name: "Nom de l'événement",
  summary: 'Resumé de l’événement',
  cancel: 'Annuler',
  add: 'Ajouter',
  view_event: "visualiser l'événement",
  event_title: "Nom de l'évenement",
  cover: 'Photo de l’événement',
  cover_image: 'Photo de couverture',
  category: 'Catégorie',
  sub_category: 'sous-catégorie',
  status: 'Statut',
  listed: 'Listé',
  online_event: 'Evénement en Ligne',
  tags: 'Mots-clés',
  address: 'Adresse',
  city: 'Cité',
  capacity: 'Capacité',
  save: 'Enregistrer',
  Start_Time: "Heure d'ouverture de l'événement",
  End_Time: "Heure de clôture de l'événement",
  timezone: 'Fuseau horaire',
  Save_Changes: 'Sauvegarder les changements',
  Quantity: 'Quantité',
  Initial_Quantity: 'Quantité disponible',
  Max_Quantity: 'Max billets par commande',
  price: 'Prix',
  free: 'Gratuit',
  Sell_Time_Limit: 'Limite du temps de vente',
  delete: 'Supprimer',
  Edit: 'Editer',
  Add_Ticket: 'Ajouter Ticket',
  Sell_limit: 'Limite de vente',
  start: 'Début',
  end: 'Fin',
  Access_Time_Limit: "Heure d'accès à l'événement",
  Price_Quantity: 'Prix et Quantité',
  Access_limit: "Limite d'accès",
  name: 'Nom du Ticket',
  Your_event_is_already_published_on_this_link: 'Votre évènement est déjà publié sur ce lien',
  archive: 'Archiver',
  Event_Dashboard: "Dashboard de l'évenement",
  Preview_Event: "Aperçu de l'événement",
  publish: 'Publier',
  Email_Message: "Texte de l'email envoyé aux  participants à votre événement",
  Ticket_Message: 'Description du ticket',
  include: 'Inclus',
  required: 'Requis',
  Gender: 'Genre',
  Birthday: 'Date de naissance',
  HomePhone: 'Téléphone fixe',
  yourEventIs: 'Votre événement est ',
  date: 'Date',
  events_catering: 'Evénementiel et restauration',
  events_catering_description:
    'ID est une plateforme qui permet à vos clients d’acheter des billets pour assister à vos événements  ainsi que commander leur nourriture et boisson digitalement dans votre établissement.',
  create_digital_exp: 'Créez votre expérience digitale',
  create_digital_exp_description:
    'Les clients interagissent avec votre marque, à votre façon; vous contrôlez votre présence numérique afin de maximiser les revenus et la rentabilité.',
  digital_ticketing: 'LA BILLETTERIE DIGITALE',
  digital_ticketing_title:
    'Ajoutez vos événements et mettez en vente les billets sur notre plateforme.',
  digital_ticketing_description:
    'Chacun de vos clients désireux de participer, pourra cliquer sur un lien ou scanner un qr code sur le flyer digital ou physique de votre événement; ce dernier le dirigera vers la plateforme ID où il pourra sélectionner et payer sa place.',
  more_questions: 'Vous avez encore des questions?',
  more_questions_description:
    'Veuillez décrire votre cas pour recevoir les conseils les plus précis.',
  who_are_we: {
    who: 'Qui',
    are: 'sommes',
    we: 'Nous',
    work: 'Travaillons ensemble pour',
    futur: 'un meilleur avenir événementiel',
  },
  what_is_id: {
    title: "C'est quoi ID?",
    description:
      'ID est une plateforme qui permet à vos clients d’acheter des billets pour assister à vos événements  ainsi que commander leur nourriture et boisson digitalement dans votre établissement.',
  },
  about: {
    vision:
      'Notre vision: offrir la plateforme parfaite pour un avenir meilleur dans l’ événementiel et la restauration.',
    dream_team: 'DREAM TEAM',
    dream_team_description:
      "ID vous fournira une assistance si vous rencontrez des problèmes, notre équipe d'assistance vous répondra en moins d’un jour et nous avons également une documentation détaillée.",
  },
  testimonials: {
    title: 'TÉMOIGNAGES',
    who: 'Qui aime notre service? ',
    description:
      "Notre objectif est de créer la plateforme parfaite qui satisfera tous nos clients dans l’événementiel et la restauration. C'est pourquoi nous travaillons constamment sur nos services pour les améliorer chaque jour en écoutant les précieuses suggestions  que nos utilisateurs nous partagent.",
  },
  Min_Quantity: 'Min billets par commande',
  save_continue: 'Enregistrer et continuer',
  continue_later: 'Ne pas enregistrer et continuer plus tard',
  Link_to_buy_tickets_for_your_event: 'Lien pour acheter des places à votre événement',
  back: 'Retour',
  Complete_the_details_of_the_event_to_be_able_to_publish_it:
    "Compléter les détails de l'évènement pour pouvoir la publier",
  publish_the_event: "Publier l'évènement",
  view: 'Voir',
  ready_events: 'Événements prêts',
  drafted_events: 'Événements brouillons',
  archived_events: 'Événements archivés',
  canceled_events: 'Événements annulés',
  no_events: "Pas d'événements",
  my_events: 'Mes evénements',
  total_sold: 'Total des recettes',
  sold_quantity: 'Nombre de billets vendus',
  event_link: "Lien de l'événement",
  sale_date: 'Date de vente',
  validated: 'Validés',
  confirmed: 'Confirmés',
  reimbursed: 'Remboursés',
  About: 'A propos',
  Name: 'Nom',
  promoters: 'PromoBoosters',
  add_promoter: 'Ajouter PromoBooster',
  choose_promoter: 'Choisir PromoBooster',
  remove: 'Retirer',
  min_followers: "Minimum d'abonnés",
  Dashboard: 'Tableau de bord',
  General: 'Général',
  Preview: 'Aperçu',
  required_information: 'Informations requises',
  promo_code: 'Codes Promo',
  overview: 'Vue globale',
  preview_your_event: "Aperçu de l'événement",
  Owner: 'Propriétaire',
  Account: 'Compte',
  account_settings: 'Paramètres du compte',
  Allowed_format: 'Formats autorisés',
  max_size: 'taille maximale de',
  billing: 'facturation',
  Email_Address: 'Adresse email',
  Phone_Number: 'Numéro de téléphone',
  Country: 'Pays',
  State_Region: 'État/Région',
  Zip_Code: 'Code postal',
  discount: 'Remise',
  maximum_number_of_sold_tickets: 'nombre maximum des billets vendus',
  Featured_tickets: 'Billets en vedette',
  No_promo_code_available: 'Pas de code promo disponible',
  add_promo_code: 'Ajouter code promo',
  This_code_is_already_used: 'Ce code est déjà utilisé!',
  discount_amount: 'Montant de la remise',
  advanced_options: 'Options avancés',
  all_tickets: 'Tous les billets',
  specific_tickets: 'Billets spécifiques',
  Legal_info: 'Informations légales',
  Followers: 'Abonnés',
  Total_events: "Nombre d'événements",
  Recommended: 'Recommendé',
  company_name: "Nom de l'entreprise",
  company_number: "Numéro de l'entreprise",
  Website: 'Site web',
  Cover: 'Couverture',
  add_organisation: 'Ajouter une organisation',
  create_user: 'créer un utilisateur',
  users: 'utilisateurs',
  since: 'Depuis',
  firstname: 'Prénom',
  lastname: 'Nom',
  role: 'Rôle',
  access: 'accès',
  events_permissions: "Autorisations d'événements",
  organisations_permissions: 'Autorisations des organisations',
  Yes: 'Oui',
  No: 'Non',
  Permissions: 'Autorisations',
  Editing: 'Edition',
  Roles: 'Rôles',
  Search: 'Recherche',
  Event_stats: "Statistiques de l'événement",
  Number_of_purchase: "Nombre d'achats",
  Link: 'Lien',
  sold: 'vendus',
  available: 'disponibles',
  Tickets_status: 'Etat des tickets',
  There_is_no_tickets_status_for_this_event: "Il n'y a pas état de billets pour cet événement",
  promoter: 'PromoBooster',
  invite_user: 'Inviter utilisateur',
  initial: 'initial',
  upcoming: 'à venir',
  ongoing: ' en cours',
  past: 'passé',
  Organisation: 'Organisation',
  Bio: 'Bio',
  Type: 'Type',
  Evenementiel: 'Evenementiel',
  Restaurant: 'Restaurant',
  Email: 'Email',
  Rajoutez_une_image_de_couverture_pour_votre_événement:
    'Rajoutez une image de couverture pour votre événement',
  Upload_photo: 'Télécharger une photo',
  Update_photo: 'Mettre à jour la photo',
  organisation_users: "Utilisateurs de l'organisation",
  Agent_liés_à_lorganisation: "Agents liés à l'organisation",
  Agents_en_attente_de_confirmation: 'Agents en attente de confirmation',
  Instadrink_admin: 'Administrateur Instadrink',
  create_role: 'Créer un role',
  Predefined_roles: 'Rôles prédéfinis',
  Custom_roles: 'Rôles personnalisés',
  Agents: 'Agents',
  Organizer: 'Organisateur',
  Bouncer: 'Videur',
  Ambassador: 'Ambassadeur',
  promotion_groups: 'groupes de promotion',
  Dates: 'Dates',
  Tickets: 'Tickets',
  Information: 'Informations',
  Messages: 'Messages',
  Promotion: 'Promotion',
  Participants: 'Participants',
  Client: 'Client',
  description_placeholder:
    'Décrivez à vos futurs participants l’ambiance exceptionnelle qui règnera dans votre évènement',
  Tickets_collection: 'Collection des tickets',
  Stop_sell_time_is_at: "L'heure limite de vente est à",
  drag_and_drop: "Glissez et déposez pour changer l'ordre",
  delete_ticket_confirmation: 'Êtes-vous sûr de vouloir supprimer ce billet ?',
  Access_Time_Thresholdt: "Heure de fin d'accès de l'évènement",
  order: 'ordre',
  Créez_les_tickets_pour_votre_événement: 'Créez les tickets pour votre événement',
  stop_sell_time_error_message:
    'La date de fin doit être postérieure à la date de début et ne doit pas dépasser la date de fin de vente',
  Reset: 'Réinitialiser',
  Tickets_quantity_error_message:
    "La quantité totale de tickets a atteint la capacité maximale de l'événement, qui est",
  Write_something_awesome: "Écris quelque chose d'incroyable...",
  Code: 'Code',
  delete_promocode_confirm_message: 'Êtes-vous sûr de vouloir supprimer ce code ?',
  Activate_promotion: 'Activer la promotion',
  Desactivate_promotion: 'Désactiver la promotion',
  Configure_RP_offer: "Configurer l'offre PromoBooster",
  promotion_status: 'statut de la promotion',
  TooltipTextForPromotionGroups:
    'Dans cette section, vous pouvez créer et gérer des groupes de Personnes Ressources (PR) pour vos événements.',
  TooltipTextForEventLink: "Publiez votre événement pour obtenir votre lien d'événement.",
  TooltipTextForPromotionGroupsForReferral:
    'Dans cette section, vous trouverez les groupes PromoBooster dont vous êtes membre, et vous pouvez quitter le groupe à tout moment.',
  confirm_delete_promoter: 'Êtes-vous sûr de vouloir supprimer ce PromoBooster ?',
  Preview_promoter_link: 'Aperçu du lien du PromoBooster',
  Locked: 'Verouillé',
  pending: 'En attente',
  accepted: 'accepté',
  rejected: 'rejeté',
  Publish_my_event_now: 'Publier mon évènement maintenant',
  publish_later: 'Publier plus tard',
  Your_event_is_still_draft:
    'Votre évènement est toujours un brouillon, terminer la configuration pour le publier',
  Are_you_sure_to_publish_the_event: "Êtes-vous sûr de publier l'événement  ",
  pourcentage: 'pourcentage',
  Rate: 'Taux',
  Message: 'Message',
  submit: 'soumettre',
  Create_group: 'Créer un groupe',
  Groups: 'Groupes',
  Edit_group: 'Editer le groupe',
  Search_by_Name: 'Rechercher par nom',
  Enter_group_name: 'Entrez le nom du groupe',
  No_promoters_found: 'Aucun PromoBooster trouvé',
  Add_group: 'Ajouter le groupe',
  Number_of_sales: 'Nombre de ventes',
  Turnover: "Chiffre d'affaires",
  Number_of_clients: 'Nombre de clients',
  Promotion_requests: 'Demandes de promotion',
  Requests: 'Demandes',
  Notifications: 'Notifications',
  Referral_requests: 'Demandes de PromoBooster',
  no_requests: "Il n'y a aucune demande",
  request_message: 'vous a envoyé une demande pour vous inviter à être le Promobooster de',
  Accept: 'Accepter',
  Reject: 'Rejeter',
  Your_invitation_to: 'Votre invitation à',
  on: 'le',
  at: 'à',
  is: 'est',
  promotion_rate: 'taux de promotion',
  consigne: 'consigne',
  Cancel_request: 'Annuler la demande',
  Send_request: 'Envoyer une demande',
  No_events:
    'Recherchez un événement avec lequel vous aimeriez collaborer en tant que PromoBooster',
  Search_for_new_events: 'Rechercher de nouveaux événements',
  Search_for_Events: 'Rechercher des événements',
  Sales_stats: 'Statistiques de ventes',
  Total_sales: 'Total ventes',
  Total_reservation: 'Total reservations',
  Bienvenue_message: 'Bienvenue sur Instadrink, choisissez votre domaine',
  second_bienvenu_message:
    "Bienvenue sur Instadrink, sélectionnez votre domaine d’activité entre l'évenementiel et la restauration.",
  Continue: 'Continuer',
  Event: 'Evénement',
  orga_message: 'choisissez l’organisation sous laquelle vous créerez votre événement',
  Skip: 'Passer',
  All_steps_completed: 'Toutes les étapes terminées',
  you_have_finished: 'vous avez terminé',
  limit_sell_message: 'Entrez une date limite et une heure de vente des tickets',
  stopSellDate: 'Date de fin de vente des tickets',
  status_tooltip:
    "Ce champ de statut calcule automatiquement la date de début et la date de fin, et vous donne le statut de l'événement",
  Step: 'Etape',
  correct_start_date: 'Corriger la date de démarrage passé',
  Finish: 'Teminer',
  event_name_tooltip: 'Quel est le nom de votre événement?',
  event_summary_tooltip:
    'Donnez un avant-goût des belles surprises qui attendent les futurs participants à votre événement.',
  event_description_tooltip:
    'Cette fois, décrivez en détails votre événement auquel les futurs participants auront la chance de participer.',
  event_cover_tooltip: 'choisissez une photo pour votre événement',
  organisation_tooltip: 'choisissez le nom de l’organisation qui organisera votre événement',
  category_tooltip: 'choisissez une catégorie pour votre événement',
  subCategory_tooltip: 'choisissez une sous-catégorie pour votre événement',
  Business: 'Affaires',
  Charity: 'Charité',
  Causes: 'Causes',
  Community: 'Communauté',
  Concerts: 'Concerts',
  Music: 'Musique',
  Conferences: 'Conférences',
  Expos: 'Expositions',
  Fashion: 'Mode',
  Festivals: 'Festivals',
  Film: 'Cinéma',
  Media: 'Médias',
  Food: 'Nourriture',
  Drink: 'Boisson',
  Health: 'Santé',
  Hobbies: 'Loisirs',
  Nightlife: 'Vie nocturne',
  Other: 'Autre',
  Performing: 'Arts du',
  arts: 'spéctacle',
  Seasonal: 'Saisonnier',
  Holiday: 'Vacances',
  Sports: 'Sports',
  Fitness: 'Forme physique',
  Travel: 'Voyage',
  Outdoor: 'Plein air',
  Career: 'Carrière',
  Design: 'Conception',
  Educators: 'Éducateurs',
  Environment: 'Environnement',
  Sustainability: 'Durabilité',
  Finance: 'Finance',
  Investment: 'Investissement',
  Non: 'Non',
  Profit: 'But lucratif',
  NGOs: 'ONG',
  Real: 'Réel',
  Estate: 'Immobilier',
  Sales: 'Ventes',
  Marketing: 'Marketing',
  Startups: 'Startups',
  Small: 'Petites',
  Animal: 'Animaux',
  Welfare: 'Bien-être',
  Art: 'Art',
  Auctions: 'Ventes aux enchères',
  Benefit: 'Bénéfice',
  Walks: 'Marches',
  Disaster: 'Catastrophe',
  Relief: 'Secours',
  Donor: 'Donateur',
  Recognition: 'Reconnaissance',
  Educational: 'Éducatives',
  Fundraising: 'Collecte de fonds',
  Homeless: 'Sans-abri',
  shelters: 'Refuges',
  Human: 'Humains',
  Rights: 'Droits',
  International: 'International',
  Aid: 'Aide',
  Marches: 'Marches',
  Poverty: 'Pauvreté',
  Rallies: 'Rassemblements',
  Demonstrations: 'Manifestations',
  Ethnic: 'Ethnique',
  Fairs: 'Foires',
  Farmers: 'Agriculteurs',
  markets: 'marchés',
  Fetes: 'Fêtes',
  Fun: 'Amusant',
  runs: 'courses',
  Heritage: 'Patrimoine',
  Historic: 'Historique',
  Language: 'Langue',
  LGBTQ: 'LGBTQ',
  Nationality: 'Nationalité',
  Parades: 'Défilés',
  Protests: 'Manifestations',
  Religious: 'Religieux',
  Renaissance: 'Renaissance',
  Spiritual: 'Spirituel',
  Town: 'Ville',
  hall: 'salle',
  meetings: 'réunions',
  Acoustic: 'Acoustique',
  Americana: 'Américaine',
  Bluegrass: 'Bluegrass',
  Blues: 'Blues',
  Jazz: 'Jazz',
  Classical: 'Classique',
  Cultural: 'Culturel',
  Dance: 'Danse',
  EDM: 'Musique électronique',
  Electronic: 'Électronique',
  Experimental: 'Expérimental',
  Folk: 'Folk',
  Hip: 'Hip',
  Hop: 'Hop',
  Rap: 'Rap',
  Indie: 'Indépendant',
  Latin: 'Latin',
  Metal: 'Métal',
  Opera: 'Opéra',
  Pop: 'Pop',
  Psychedelic: 'Psychedelique',
  Reggae: 'Reggae',
  Rock: 'Rock',
  Singer: 'Chanteur',
  Songwriter: 'auteur-compositeur',
  Top: 'Top',
  World: 'Monde',
  Colloquium: 'Colloque',
  Conference: 'Conférence',
  Congress: 'Congrès',
  Convention: 'Convention',
  Demonstration: 'Démonstration',
  Exhibition: 'Exposition',
  Forum: 'Forum',
  Meeting: 'Réunion',
  Presentation: 'Présentation',
  Round: 'Rond',
  table: 'table',
  Seminar: 'Séminaire',
  Symposium: 'Symposium',
  Workshop: 'Atelier',
  Award: 'Récompense',
  Ceremonies: 'Cérémonies',
  Screening: 'Projection',
  Movie: 'Film',
  Premiere: 'Première',
  Television: 'Télévision',
  Video: 'Vidéo',
  Game: 'Jeu',
  Education: 'Éducation',
  Industry: 'Industrie',
  Science: 'Science',
  Technology: 'Technologie',
  Barbecue: 'Barbecue',
  Breakfast: 'Petit déjeuner',
  Brunch: 'Brunch',
  Buffet: 'Buffet',
  Cocktail: 'Cocktail',
  Mixology: 'Mixologie',
  Culinary: 'Culinaire',
  workshops: 'ateliers',
  Dessert: 'Dessert',
  Pastry: 'Pâtisserie',
  Tastings: 'Dégustations',
  Dinner: 'Dîner',
  Farm: 'Dîner',
  to: 'De la ferme',
  Table: 'à',
  Dining: 'la table',
  Gourmet: 'Gastronomique',
  Markets: 'Marchés',
  Lunch: 'Déjeuner',
  Picnic: 'Pique-nique',
  Wine: 'Vin',
  Beer: 'Bière',
  Adult: 'Adulte',
  Anime: 'Animé',
  Comics: 'Bandes dessinées',
  Book: 'Livre',
  Books: 'Livres',
  Collecting: 'Collection',
  Cooking: 'Cuisine',
  DIY: 'Faites-le vous-même',
  Crafts: 'Artisanat',
  Drawing: 'Dessin',
  Painting: 'Peinture',
  Gaming: 'Jeux',
  Gardening: 'Jardinage',
  Home: 'Amélioration',
  Improvement: "de l'habitat",
  Knitting: 'Tricot',
  Photography: 'Photographie',
  Reading: 'Lecture',
  Sewing: 'Couture',
  Writing: 'Écriture',
  Bar: 'Tournées',
  Crawls: 'des bars',
  Bingo: 'Loto',
  Cabaret: 'Cabaret',
  Casino: 'Casino',
  Comedy: 'Comédie',
  Clubs: 'Clubs',
  Parties: 'Fêtes',
  Dancehall: 'Dancehall',
  Drinks: 'Boissons',
  Karaoke: 'Karaoké',
  Nightclubs: 'Boîtes de nuit',
  Open: 'Micro',
  Mic: 'ouvert',
  Pubs: 'Pubs',
  Raves: 'Raves',
  Medical: 'Médical',
  Spa: 'Spa',
  Yoga: 'Yoga',
  Circus: 'Cirque',
  Exhibitions: 'Expositions',
  Magic: 'Magie',
  Musicals: 'Comédies musicales',
  Plays: 'Pièces de théâtre',
  Theater: 'Théâtre',
  Christmas: 'Noël',
  Easter: 'Pâques',
  Halloween: 'Halloween',
  Hanukkah: 'Hanoucca',
  New: 'Réveillon ',
  Year: 'du',
  Eve: 'Nouvel An',
  Thanksgiving: 'Action de grâce',
  Archery: "Tir à l'arc",
  Baseball: 'Baseball',
  Basketball: 'Basket-ball',
  Camping: 'Camping',
  Camps: 'Camps',
  Cars: 'Voitures',
  Cheer: 'Pom-pom girl',
  Climbing: 'Escalade',
  Cycling: 'Cyclisme',
  Diving: 'Plongée',
  Exercise: 'Exercice',
  Fishing: 'Pêche',
  Football: 'Football',
  Golf: 'Golf',
  Hiking: 'Randonnée',
  Hockey: 'Hockey',
  Hunting: 'Chasse',
  Jogging: 'Jogging',
  Lacrosse: 'Lacrosse',
  Motorsports: 'Sports mécaniques',
  Obstacles: "Parcours d'obstacles",
  Parachuting: 'Parachutisme',
  Rally: 'Rallye',
  Rugby: 'Rugby',
  Running: 'Course à pied',
  Skating: 'Patinage',
  Skiing: 'Ski',
  Snow: 'hiver',
  Snowboarding: 'Snowboard',
  Soccer: 'Football',
  Softball: 'Softball',
  Sport: 'Sport',
  Surfing: 'Surf',
  Swimming: 'Natation',
  Water: 'nautiques',
  Tennis: 'Tennis',
  Volleyball: 'Volleyball',
  Walking: 'Marche',
  Weightlifting: 'Haltérophilie',
  Windsurfing: 'Planche à voile',
  Wrestling: 'Lutte',
  Cruise: 'Croisière',
  Expeditions: 'Expéditions',
  Museum: 'Musée',
  visit: 'visite',
  Road: 'Voyages',
  Trips: 'en voiture',
  tours: 'Visites',
  Rp_request: 'vous a envoyé une demande pour être le PromoBooster de son événement',
  warning_message:
    'Veuillez d\'abord appuyer sur "ajouter" pour enregistrer votre billet et continuer',
  correct_date_tooltip: 'La date de début sélectionnée pour votre événement est déjà passée.',
  step1:
    "À cette étape, vous trouverez les statistiques de ventes de l'événement et la liste des participants à l'événement.",
  step2: "À cette étape, vous pouvez modifier les détails de l'événement.",
  step3: "À cette étape, vous pouvez modifier les dates et heures de l'événement.",
  step4:
    'À cette étape, vous pouvez ajouter de nouveaux billets pour votre événement ou modifier les billets existants.',
  step5: 'À cette étape, vous pouvez publier ou archiver votre événement.',
  step6:
    "À cette étape, vous trouverez la vue de l'événement pour les clients en mode bureau ou mobile.",
  step7: 'À cette étape, vous trouverez un aperçu des détails de votre événement.',
  step8: "À cette étape, vous pouvez obtenir des informations sur l'événement.",
  step9: 'À cette étape, vous pouvez ajouter des codes promotionnels pour les billets.',
  step10:
    'À cette étape, vous pouvez gérer les PromoBooster de votre événement, créer un groupe de PromoBooster ou envoyer des demandes pour que le PromoBooster soit PromoBooster de votre événement.',
  step11: 'À cette étape, vous trouverez la liste des participants.',
  total_sold_tooltip: "Il s'agit du prix de vente total des billets pour l'événement.",
  sold_quantity_tooltip: "Il s'agit de la quantité totale de billets vendus pour l'événement.",
  link_tooltip: "Il s'agit du lien vers l'événement où vous pouvez acheter vos billets.",
  table_tooltip: 'Voici le tableau où vous trouverez la liste des participants à cet événement.',
  create_event_button_tooltip: 'Cliquez ici pour créer un nouvel événement.',
  events_table_tooltip:
    "Il s'agit du tableau des événements où vous pouvez trouver les détails de vos événements.",
  You_have_no_organisations: "Vous n'avez aucune organisation",
  alert_orga_title: 'Vous devez créer une organisation pour pouvoir créer un événement ensuite.',
  Configurate_here: 'Configurez ici',
  legal_verification: 'Vérification légale de votre compte',
  alert_stripe_configuration_title:
    'Veuillez compléter vos informations légales pour activer votre compte et pouvoir vendre vos billets.',
  Bank_balance: 'Solde bancaire',
  View_my_Stripe_account: 'Consultez mon compte Stripe',
  Your_balance: 'Votre solde',
  Add_new_card: 'Ajouter une nouvelle carte',
  Name_on_card: 'Nom sur la carte',
  Card_number: 'Numéro de carte',
  Expiration_date: "Date d'expiration",
  QRcode_tooltip: 'Cliquez ici pour personnaliser et télécharger le code QR de votre événement',
  File_Type: 'Type de fichier',
  send_invitation: 'Envoyer une invitation',
  Size: 'Taille',
  Color: 'Couleur',
  Background_Color: 'Couleur de fond',
  Download_QR_Code: 'Télécharger le code QR',
  Download_Invoice: 'Télécharger la facture',
  Close: 'Fermer',
  Customize_QR_Code: 'Personnaliser le code QR',
  no_users_yet: 'Aucun utilisateur pour le moment',
  plz_invite_users: 'Veuillez inviter des utilisateurs',
  invite_organizer: 'Inviter organisateur',
  invite: 'inviter',
  settings: 'configuration',
  invite_owners: 'inviter propriétaires',
  register: 'S’inscrire',
  password: 'Mot de passe',
  confirm_password: 'Confirmer le mot de passe',
  email_adress: 'Adresse email',
  division_agent: 'Agent',
  division_promoter: 'PromoBooster',
  invite_owner: 'Je souhaite inviter un propriétaire',
  invite_referrer: 'Je souhaite inviter un PromoBooster',
  invite_ambassador: 'Je souhaite inviter un ambassadeur',
  iam_owner: 'Je suis un proprietaire',
  iam_owner_desc: 'Gérez vos organisations, restaurants et vos événements',
  iam_amb: 'Je suis un ambassadeur',
  iam_amb_desc: 'Promouvez des organisations et gagnez des commissions',
  iam_ref: 'Je suis un PromoBooster',
  iam_ref_desc: 'Référez des évenements et gagnez des commissions',
  start_free: 'Commencez tout à fait gratuitement.',
  no_credit_card: 'Aucune carte de crédit nécessaire.',
  have_account: 'Vous avez déjà un compte?',
  by_register_1: "En m'inscrivant, j'accepte les ",
  by_register_2: "Conditions d'utilisation",
  by_register_3: ' et la ',
  by_register_4: 'Politique de confidentialité.',
  manager_better_register: 'Gérez plus efficacement vos événements avec Instadrink',
  referral: 'PromoBooster',
  first_name_required: 'Prénom requis',
  first_name_short: 'Le prénom est trop court - doit comporter au moins 3 caractères.',
  first_name_long: 'Le prénom est trop long - doit comporter 50 caractères maximum.',
  first_name_alpha: 'Le prénom ne peut contenir que des caractères alphabétiques.',
  last_name_required: 'Nom de famille requis',
  last_name_short: 'Le nom de famille est trop court - doit comporter au moins 3 caractères.',
  last_name_long: 'Le nom de famille est trop long - doit comporter 50 caractères maximum.',
  last_name_alpha: 'Le nom de famille ne peut contenir que des caractères alphabétiques.',
  email_invalid: "L'email doit être une adresse email valide",
  email_required: 'Email requis',
  password_required: 'Mot de passe requis',
  password_short: 'Le mot de passe est trop court - doit comporter au moins 8 caractères.',
  password_alpha: 'Le mot de passe ne peut contenir que des lettres latines.',
  password_uppercase: 'Le mot de passe doit contenir au moins une lettre majuscule.',
  password_number: 'Le mot de passe doit contenir un chiffre.',
  confirm_password_match: 'Les mots de passe doivent correspondre',
  confirm_password_required: 'Confirmer le mot de passe est requis',
  division_required: 'Division requise',
  division_invalid: 'La division doit être soit PromoBooster soit Agent',
  role_required: 'Rôle requis',
  role_invalid_promoter: 'Rôle invalide pour le PromoBooster',
  role_invalid_agent: "Rôle invalide pour l'agent",
  login: 'Connexion',
  fr: 'Français',
  en: 'English',
  es: 'Espagnol',
  pt: 'Portugais',
  lang: 'Langue',
  creating_account: 'Création de Compte en Cours',
  creating_account_desc: 'Finalisation de Votre Configuration de Compte',
  creating_account_info:
    "Votre compte est en cours de configuration. Veuillez rester sur cette page jusqu'à ce que le processus soit terminé. Nous apprécions votre patience !",
  accept_invitation_info:
    "Veuillez noter qu'après avoir accepté l'invitation ci-dessous, vous recevrez un e-mail de vérification. Après vérification, vous pourrez compléter la configuration de votre compte, y compris les options pour modifier votre nom et votre langue préférée.",
  verify_email: 'Vérifiez Votre E-mail',
  verify_email_desc: 'Compte Créé avec Succès',
  verify_email_info:
    'Félicitations pour la configuration de votre compte ! Veuillez vérifier votre boîte de réception pour confirmer votre compte et accéder à toutes les fonctionnalités.',
  wait_verify: 'Veuillez patienter un moment pendant que nous vérifions votre e-mail.',
  wait_redirect: "Ne fermez pas cette fenêtre avant d'être redirigé vers le tableau de bord.",
  initialAccountHeader: 'Finalisez votre compte Instadrink',
  initialAccountDescription:
    'Bon retour! Définissez simplement votre mot de passe ci-dessous pour finaliser la configuration de votre compte.',
  initialAccountButton: 'Terminer la configuration',
  processingAccountHeader: 'Configuration de votre compte...',
  processingAccountDescription:
    'Veuillez patienter un instant pendant que nous confirmons vos informations et sécurisons votre compte.',
  processingAccountButton: 'En cours de finalisation...',
  successAccountHeader: 'Succès! Compte prêt',
  successAccountDescription:
    'Votre compte a été configuré avec succès et vous êtes en train de vous connecter. Vous allez être redirigé vers le tableau de bord sous peu.',
  successAccountButton: 'Redirection...',
  errorAccountHeader: 'Un problème est survenu',
  errorAccountDescription:
    "Il y a eu un problème avec la configuration de votre compte. Le lien pourrait être invalide ou expiré, ou l'adresse e-mail pourrait être incorrecte.",
  errorAccountButton: 'Réessayer',
  login_welcome: 'Salut, Bienvenue',
  login_connect: 'Connectez-vous à Instadrink',
  login_type: 'Entrez vos coordonnées ci-dessous.',
  login_no_account: "Vous n'avez pas de compte ?",
  login_register: "S'inscrire",
  owner_total_part: "Il s'agit des parts du propriétaire pour la vente des tickets de l'événement.",
  my_share: 'Ma part',
  network: 'Réseau',
  Remember_password: 'Se souvenir du mot de passe',
  forgot_password: 'Mot de passe oublié?',
  create_your_first_organization: 'Créez votre première organisation',
  create_organization_step_description:
    "Pour commencer à gérer vos événements, créez votre première organisation. Vous pouvez créer autant d'organisations que vous le souhaitez plus tard.",
  create_your_first_event: 'Créez votre premier événement',
  create_event_step_description:
    'Maintenant que vous avez créé votre première organisation, il est temps de créer brièvement votre premier événement. Vous pourrez le modifier en détail plus tard.',
  congratulations: 'Félicitations !',
  last_step_description:
    'Vous pouvez maintenant accéder à la liste des événements et continuer à créer votre événement.',
  create_your_first_restaurant: 'Créez votre premier restaurant',
  create_restaurant_step_description:
    'Pour commencer à gérer vos tables, créez votre premier restaurant. Vous pouvez créer autant de restaurants que vous le souhaitez plus tard.',
  create_restaurant_last_step_description:
    'Vous pouvez maintenant accéder à la liste des restaurants et continuer à créer vos tables.',
  Logout: 'Déconnexion',
  Settings: 'Paramètres',
  create_store: 'Créer un restaurant',
  add_category: 'Ajouter une catégorie',
  select_store: 'Sélectionner un restaurant',
  add_new_product: 'Ajouter un nouveau produit',
  add_new_category: 'Ajouter une nouvelle catégorie',
  products: 'Produits',
  categories: 'Catégories',
  unavailable: 'Indisponible',
  composite: 'Composite',
  alcoholic: 'Alcoolique',
  Available: 'Disponible',
  Description: 'Description',
  simple: 'Simple',
  search_product: 'Rechercher un produit...',
  min_price: 'Prix minimum',
  max_price: 'Prix maximum',
  add_product: 'Ajouter un produit',
  edit_product: 'Modifier le produit',
  product_details: 'Détails du produit',
  composition_items: 'Éléments de composition',
  caption: 'Légende',
  extra: 'Supplément',
  max_quantity: 'Quantité maximale',
  add_composition: 'Ajouter une composition',
  items: 'Articles',
  item_name: "Nom de l'article",
  add_item: 'Ajouter un article',
  preparation_time: 'Temps de préparation',
  Orders: 'Commandes',
  current_orders: 'Commandes Actuelles',
  store: 'Restaurant',
  no_stores_message:
    "Vous n'avez aucun restaurant pour voir les commandes, veuillez d'abord en créer un",
  Pending: 'En Attente',
  in_preparation: 'En Préparation',
  Preparation: 'Préparation',
  accept_order: 'Accepter la commande',
  order_is_ready: 'La commande est prête',
  finish_order: 'Terminer la commande',
  total: 'Total',
  order_of: 'Commande de',
  Contact: 'Contact',
  reservations_shares: 'Partages de réservations',
  client_name: 'Nom du client',
  total_amount: 'Montant total',
  owner_share: 'Part du propriétaire',
  instadrink_share: "Part d'Instadrink",
  rp_share: 'Part de PromoBooster',
  thanks_join_invite: 'Merci de rejoindre notre communaute !',
  account_created_check_email: 'Compte créé, veuillez valider votre email',
  verify_before_expire: 'Vérifier votre mail avant expiration',
  if_mistake_contact_instadrink: 'Si vous avez une erreur, contactez Instadrink',
  ambassador_share: "Part de l'ambassadeur",
  shares_distribution: 'Répartition des parts',
  group_name: 'Nom du groupe',
  group_members: 'Membres du groupe',
  created_by: 'Créé par',
  leave_group: 'Quitter le groupe',
  with_product: 'Avec produit',
  all_categories: 'Toutes les catégories',
  inclusives: 'Inclusives',
  closing_Time: 'Heure de fermeture',
  open: 'Ouvert',
  Rush_hour: 'Heure de pointe',
  preview_store: 'Aperçu du restaurant',
  Delete_account: 'Supprimer le compte',
  invitation_rejected: 'Invitation rejeté',
  Are_you_sure_you_want_to_delete_your_account: 'Êtes-vous sûr de vouloir supprimer votre compte',
  delete_account_page_message:
    'En choisissant de supprimer votre compte, vous optez pour la suppression permanente de toutes vos données de notre système.',
  restaurants: 'Restaurants',
  stores: 'Magasins',
  commandes: 'Commandes',
  applications: 'Applications',
  inviteUsers: 'Inviter des utilisateurs',
  inviteAgents: 'Inviter des agents',
  inviteStaff: 'Inviter du personnel',
  invitationsList: 'Liste des invitations',
  promotersTree: 'Arbre des invitations',
  promotionGroups: 'Groupes de PromoBoosters',
  onboarding: 'Onboarding',
  invite_more_users: "Inviter plus d'utilisateurs",
  your_organisation_staff: 'Les employés de votre organisation',
  inv_org_desc: "L'organisateur de votre événement",
  inv_bnc_desc: "Le controleur d'accès à votre évènement",
  invite_more_staff: 'Inviter plus du personnel',
  duplicate_event: "Dupliquer l'événement",
  duplicate: 'Dupliquer',
  reservations: 'Réservations',
  multiple_persons: 'Plusieurs personnes',
  persons_number: 'Nombre de personnes',
  vatRate: 'Taux TVA',
  price_TTC: 'Prix TTC',
  Occupation: 'Profession',
  priceHt: 'Prix hors TVA',
  promobooster_mode: 'Mode Promobooster',
  Amount: 'Montant',
  Percentage: 'Pourcentage',
  include_owners: 'inclure les propriétaires',
   add_anonymous_promoter: 'Ajouter un SpyBooster', 
  Confirm_Name: 'Confirmer le nom', 
  Add_Anonymous_Name: 'Ajouter un SpyBooster', 
  ask_for_invoice: 'demander une facture',
  add_invoice_button: 'Ajouter ',
  enter_valid_invoice: 'Veuillez saisir une facture valide',  
  Compan_Name_Last_Name :'Société/Nom/Nom de famille',
  tva :'TVA',
  adresse :'Adresse',
  postal_code :'Code Postal', 
  country :'Country',
  additional_information :'Informations Complémentaires', 
  invoice_information :'Facturation',
  know_from_invoice :'Quelles informations exigez-vous de votre facture ?',
  know_from_attendees :'Que devez-vous savoir de vos participants ?',
  add_invoice: 'Ajouter un nouveau champ',
  active_invoice: 'Activer la facture',
  actived_invoice: 'La facture est activée', 
  preview_your_share: 'Aperçu Répartition  ',
  Number_of_event: `Nombre d'événement`,
  time_of_creation: 'Créer à', 
  Owner_details: 'Détails du propriétaire', 

  
};

export default fr;
