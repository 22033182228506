// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to instadrinkpro@gmail.com.

// ----------------------------------------------------------------------

const pt = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Olá`,
    description: `Precisa de ajuda? \n Por favor, consulte nossa documentação.`,
    documentation: `documentação`,
  },
  app: `aplicação`,
  user: `usuário`,
  list: `lista`,
  edit: `editar`,
  shop: `loja`,
  blog: `blog`,
  post: `publicação`,
  mail: `e-mail`,
  chat: `chat`,
  cards: `cartões`,
  posts: `publicações`,
  create: `criar`,
  kanban: `kanban`,
  general: `geral`,
  banking: `banca`,
  booking: `reserva`,
  profile: `perfil`,
  account: `conta`,
  product: `produto`,
  invoice: `fatura`,
  details: `detalhes`,
  checkout: `finalizar compra`,
  calendar: `calendário`,
  analytics: `análise`,
  ecommerce: `e-commerce`,
  management: `gerenciamento`,
  menu_level_1: `menu nível 1`,
  menu_level_2: `menu nível 2`,
  menu_level_3: `menu nível 3`,
  item_disabled: `item desabilitado`,
  item_label: `etiqueta do item`,
  item_caption: `legenda do item`,
  description: `descrição`,
  other_cases: `outros casos`,
  item_by_roles: `item por funções`,
  only_admin_can_see_this_item: `Apenas o administrador pode ver este item`,
  create_event: 'Criar um evento',
  label_Rows_Per_Page: 'Linhas por página',
  created_At: 'Criado em',
  location: 'Localização',
  title: 'título',
  events: 'Eventos',
  archived: 'Arquivado',
  canceled: 'Cancelado',
  Start_Date: 'Data de Início do evento',
  End_Date: 'Data de Término do evento',
  organisation: 'Organização',
  organisations: 'Organizações',
  published_events: 'Eventos Publicados',
  Add_event: 'Adicionar Evento',
  event_name: 'Nome do Evento',
  summary: 'Resumo',
  cancel: 'Cancelar',
  add: 'Adicionar',
  view_event: 'Ver Evento',
  event_title: 'Título do Evento',
  cover: 'Capa',
  cover_image: 'Imagem de Capa',
  category: 'Categoria',
  sub_category: 'Subcategoria',
  status: 'Estado',
  listed: 'Listado',
  online_event: 'Evento Online',
  tags: 'Tags',
  address: 'Endereço',
  city: 'Cidade',
  capacity: 'Capacidade',
  save: 'Salvar',
  Start_Time: 'Hora de Início do evento',
  End_Time: 'Hora de Término do evento',
  timezone: 'Fuso Horário',
  Save_Changes: 'Salvar Alterações',
  Quantity: 'Quantidade',
  Initial_Quantity: 'Quantidade Disponível',
  Max_Quantity: 'Máximo por Pedido',
  price: 'Preço',
  free: 'Grátis',
  Sell_Time_Limit: 'Limite de Venda',
  delete: 'Excluir',
  Edit: 'Editar',
  Add_Ticket: 'Adicionar Bilhete',
  Sell_limit: 'Limite de Venda',
  start: 'Início',
  end: 'Término',
  Access_Time_Limit: 'Tempo de acesso ao evento',
  Price_Quantity: 'Preço e Quantidade',
  Access_limit: 'Limite de Acesso',
  name: 'Nome',
  Your_event_is_already_published_on_this_link: 'Seu evento já está publicado neste link',
  archive: 'Arquivar',
  Event_Dashboard: 'Painel do Evento',
  Preview_Event: 'Visualizar Evento',
  publish: 'Publicar',
  Email_Message: 'Texto do e-mail enviado aos participantes do seu evento',
  Ticket_Message: 'Mensagem do Bilhete',
  include: 'Incluir',
  required: 'Obrigatório',
  Gender: 'Gênero',
  Birthday: 'Data de Nascimento',
  HomePhone: 'Telefone Residencial',
  yourEventIs: 'Seu evento é',
  date: 'Data',

  events_catering: 'Eventos y catering',
  events_catering_description:
    'ID es una plataforma que permite a sus clientes comprar entradas para asistir a sus eventos, así como pedir su comida y bebida digitalmente en su establecimiento.',
  create_digital_exp: 'Cree su experiencia digital',
  create_digital_exp_description:
    'Los clientes interactúan con su marca, a su manera; usted controla su presencia digital para maximizar los ingresos y la rentabilidad.',
  digital_ticketing: 'VENTA DE ENTRADAS DIGITAL',
  digital_ticketing_title: 'Añada sus eventos y venda entradas en nuestra plataforma.',
  digital_ticketing_description:
    'Cada uno de tus clientes que desee participar puede hacer clic en un enlace o escanear un código QR en el folleto digital o físico de tu evento; esto les dirigirá a la plataforma ID donde podrán seleccionar y pagar su entrada.',
  more_questions: '¿Aún tiene preguntas?',
  more_questions_description: 'Describa su caso para recibir el asesoramiento más preciso.',
  who_are_we: {
    who: '¿',
    are: 'Quiénes',
    we: 'somos',
    work: 'Trabajamos juntos para',
    futur: 'un futuro mejor en eventos y la restauración',
  },
  what_is_id: {
    title: '¿Qué es ID?',
    description:
      'ID es una plataforma que permite a tus clientes comprar entradas para asistir a tus eventos, así como pedir su comida y bebida digitalmente en tu establecimiento.',
  },
  about: {
    vision:
      'Nuestra visión: ofrecer la plataforma perfecta para un futuro mejor en eventos y catering.',
    dream_team: 'EQUIPO DE SUEÑOS',
    dream_team_description:
      'ID le proporcionará asistencia si tiene algún problema. Nuestro equipo de asistencia responderá. Un buen equipo es la clave. En el plazo de un día, y también disponemos de documentación detallada.',
  },
  testimonials: {
    title: 'TESTIMONIOS',
    who: '¿A quién le gusta ',
    service: ' nuestro servicio?',
    description:
      'Nuestro objetivo es crear la plataforma perfecta que satisfaga a todos nuestros clientes de eventos y catering. Por eso trabajamos constantemente en nuestros servicios para mejorarlos cada día escuchando las valiosas sugerencias que nuestros usuarios comparten con nosotros.',
  },

  Min_Quantity: 'Mínimo por pedido',
  save_continue: 'Guardar e continuar',
  continue_later: 'Não salve e continue mais tarde',
  Link_to_buy_tickets_for_your_event: 'Link para comprar bilhetes para o seu evento',
  back: 'Voltar',
  Complete_the_details_of_the_event_to_be_able_to_publish_it:
    'Complete os detalhes do evento para poder publicá-lo',
  publish_the_event: 'Publicar o evento',
  view: 'Ver',
  ready_events: 'Eventos prontos',
  drafted_events: 'Eventos rascunho',
  archived_events: 'Eventos arquivados',
  canceled_events: 'Eventos cancelados',
  no_events: 'Sem eventos',
  my_events: 'Meus eventos',
  total_sold: 'Rendimento total',
  sold_quantity: 'Número de ingressos vendidos',
  event_link: 'Link do evento',
  sale_date: 'Data de venda',
  validated: 'Validado',
  confirmed: 'Confirmado',
  reimbursed: 'Reembolsado',
  About: 'Sobre',
  Name: 'Nome',
  promoters: 'PromoBoosters',
  add_promoter: 'Adicionar PromoBooster',
  choose_promoter: 'Escolher PromoBooster',
  remove: 'Remover',
  min_followers: 'Seguidores Mínimos',
  Dashboard: 'Painel de Controle',
  General: 'Geral',
  Preview: 'Visualizar',
  required_information: 'Informação Necessária',
  promo_code: 'Códigos de Promoção',
  overview: 'Visão Geral',
  preview_your_event: 'Visualizar seu evento',
  Owner: 'Proprietário',
  create_org: 'Criar uma organização',
  Account: 'Conta',
  account_settings: 'Configurações da Conta',
  Allowed_format: 'Formatos Permitidos',
  max_size: 'tamanho máximo de',
  billing: 'faturamento',
  Email_Address: 'Endereço de Email',
  Phone_Number: 'Número de Telefone',
  Country: 'País',
  State_Region: 'Estado/Região',
  Zip_Code: 'Código Postal',
  discount: 'Desconto',
  maximum_number_of_sold_tickets: 'Número Máximo de Bilhetes Vendidos',
  Featured_tickets: 'Bilhetes em Destaque',
  No_promo_code_available: 'Nenhum código de promoção disponível',
  add_promo_code: 'Adicionar código de promoção',
  This_code_is_already_used: 'Este código já está em uso!',
  discount_amount: 'Valor do Desconto',
  advanced_options: 'Opções Avançadas',
  all_tickets: 'Todos os bilhetes',
  specific_tickets: 'Bilhetes Específicos',
  Legal_info: 'Informação Legal',
  Followers: 'Seguidores',
  Total_events: 'Total de Eventos',
  Recommended: 'Recomendado',
  company_name: 'Nome da Empresa',
  company_number: 'Número da Empresa',
  Website: 'Site',
  Cover: 'Capa',
  add_organisation: 'Adicionar Organização',
  create_user: 'Criar Usuário',
  users: 'usuários',
  firstname: 'Primeiro Nome',
  lastname: 'Sobrenome',
  role: 'Função',
  access: 'Acesso',
  events_permissions: 'Permissões de Eventos',
  organisations_permissions: 'Permissões de Organizações',
  Yes: 'Sim',
  No: 'Não',
  Permissions: 'Permissões',
  Editing: 'Edição',
  Roles: 'Funções',
  Search: 'Buscar',
  Event_stats: 'Estatísticas do Evento',
  sales: 'vendas',
  Number_of_purchase: 'Número de Compras',
  Link: 'Link',
  sold: 'vendido',
  available: 'disponível',
  Tickets_status: 'Status dos Bilhetes',
  There_is_no_tickets_status_for_this_event: 'Não há status de bilhetes para este evento',
  promoter: 'PromoBooster',
  invite_user: 'Convidar Usuário',
  published: 'Publicado',
  ready: 'Pronto',
  draft: 'Rascunho',
  initial: 'inicial',
  upcoming: 'próximo',
  ongoing: 'em andamento',
  past: 'passado',
  Organisation: 'Organização',
  Bio: 'Biografia',
  Type: 'Tipo',
  Evenementiel: 'Eventos',
  Restaurant: 'Restaurante',
  Email: 'Correio eletrônico',
  Rajoutez_une_image_de_couverture_pour_votre_événement:
    'Adicione uma imagem de capa para o seu evento',
  Upload_photo: 'Carregar foto',
  Update_photo: 'Actualizar foto',
  organisation_users: 'Usuários da organização',
  Agent_liés_à_lorganisation: 'Agentes relacionados com a organização',
  Agents_en_attente_de_confirmation: 'Agentes aguardando confirmação',
  Instadrink_admin: 'Administrador do Instadrink',
  create_role: 'Criar um função',
  Predefined_roles: 'Funções predefinidas',
  Custom_roles: 'Funções personalizadas',
  Agents: 'Agentes',
  Organizer: 'Organizador',
  Bouncer: 'Segurança',
  Ambassador: 'Embaixador',
  promotion_groups: 'grupos de PromoBoosters',
  Dates: 'Datas',
  Tickets: 'Ingressos',
  Information: 'Informação',
  Messages: 'Mensagens',
  Promotion: 'Promoção',
  Participants: 'Participantes',
  Client: 'Cliente',
  description_placeholder:
    'Descreva aos seus futuros participantes a atmosfera excepcional que reinará no seu evento.',
  Tickets_collection: 'Coleção de ingressos',
  Stop_sell_time_is_at: 'O horário de encerramento das vendas é às',
  drag_and_drop: 'Arraste e solte para mudar a ordem',
  delete_ticket_confirmation: 'Tem certeza de que deseja excluir este bilhete?',
  Access_Time_Thresholdt: 'Horário de término do acesso ao evento',
  order: 'ordem',
  send_invitation: 'Enviar convite',
  Créez_les_tickets_pour_votre_événement: 'Crie ingressos para o seu evento',
  stop_sell_time_error_message:
    'A data de término deve ser posterior à data de início e não deve exceder a data de encerramento das vendas',
  Reset: 'Redefinir',
  Tickets_quantity_error_message:
    'A quantidade total de ingressos atingiu a capacidade máxima do evento, que é',
  Write_something_awesome: 'Escreva algo incrível...',
  Code: 'Código',
  delete_promocode_confirm_message: 'Tem certeza de que deseja excluir este código?',
  Activate_promotion: 'Ativar promoção',
  Desactivate_promotion: 'Desativar promoção',
  Configure_RP_offer: 'Configurar a oferta de PromoBooster',
  promotion_status: 'status da promoção',
  TooltipTextForPromotionGroups:
    'Nesta seção, você pode criar e gerenciar grupos de Pessoas de Recursos (PR) para seus eventos.',
  TooltipTextForEventLink: 'Publique seu evento para obter o link do seu evento.',
  TooltipTextForPromotionGroupsForReferral:
    'Nesta seção, você encontrará os grupos de PromoBooster dos quais é membro e poderá sair do grupo sempre que desejar.',
  confirm_delete_promoter: 'Tem certeza de que deseja excluir este PromoBooster?',
  Preview_promoter_link: 'Visualizar link do PromoBooster',
  Locked: 'Bloqueado',
  pending: 'Pendente',
  accepted: 'Aceito',
  rejected: 'Rejeitado',
  Publish_my_event_now: 'Publicar meu evento agora',
  publish_later: 'Publicar mais tarde',
  Your_event_is_still_draft:
    'Seu evento ainda é um rascunho, conclua a configuração para publicá-lo',
  Are_you_sure_to_publish_the_event: 'Tem certeza de publicar o evento?  ',
  pourcentage: 'percentagem',
  Rate: 'Avaliar',
  Message: 'Mensagen',
  submit: 'enviar',
  Create_group: 'criar grupo',
  Groups: 'Grupos',
  Edit_group: 'Editar grupo',
  Search_by_Name: 'Procura por nome',
  Enter_group_name: 'Insira o nome do grupo',
  No_promoters_found: 'Nenhum PromoBooster encontrado',
  Add_group: 'Adicionar grupo',
  Number_of_sales: 'Número de vendas',
  Turnover: 'Volume de negócios',
  Number_of_clients: 'Número de clientes',
  Promotion_requests: 'Solicitações de promoção',
  Requests: 'Solicitações',
  Notifications: 'Notificações',
  Referral_requests: 'Solicitações de PromoBooster',
  no_requests: 'Não há solicitações',
  request_message: 'enviou-lhe uma solicitação para convidá-lo para ser a PromoBooster de',
  Accept: 'Aceitar',
  Reject: 'Rejeitar',
  Your_invitation_to: 'Seu convite para',
  on: 'em',
  at: 'às',
  is: 'està',
  promotion_rate: 'tasa de promoción',
  consigne: 'ordem',
  Cancel_request: 'cancelar pedido',
  Send_request: 'Enviar pedido',
  No_events: 'Encontre um evento com o qual você gostaria de colaborar como PromoBooster',
  Search_for_new_events: 'Procure novos eventos',
  Search_for_Events: 'Pesquisar eventos',
  Sales_stats: 'Estatísticas de vendas',
  Total_sales: 'Vendas totais',
  Total_reservation: 'Reservas totales',
  Bienvenue_message: 'Bem-vindo ao Instadrink, escolha sua área de atuação',
  second_bienvenu_message:
    'Bem-vindo ao Instadrink, selecione sua área de atuação entre eventos e catering.',
  Continue: 'continuar',
  Event: 'Evento',
  orga_message: 'Escolha a organização sob a qual você criará seu event',
  Skip: 'Pular',
  All_steps_completed: 'Todas as etapas concluídas',
  you_have_finished: 'você terminou',
  limit_sell_message: 'Insira uma data limite e horário para a venda de ingressos',
  stopSellDate: 'Pare de vender ingressos para datas',
  status_tooltip:
    'Este campo de status calcula automaticamente a data de início e a data de término e fornece o status do evento.',
  Step: 'Etapa',
  correct_start_date: 'Corrigir a data de início passada',
  Finish: 'Terminar',
  event_name_tooltip: 'Qual é o nome do seu evento?',
  event_summary_tooltip:
    'Dê uma amostra das belas surpresas que aguardam os futuros participantes em seu evento.',
  event_description_tooltip:
    'Desta vez, descreva em detalhes o evento ao qual os futuros participantes terão a chance de participar.',
  event_cover_tooltip: 'Escolha uma foto para o seu evento.',
  organisation_tooltip: 'Escolha o nome da organização que irá hospedar o seu evento.',
  category_tooltip: 'Selecione uma categoria para o seu evento.',
  subCategory_tooltip: 'Escolha uma subcategoria para o seu evento.',
  Business: 'Negócios',
  Charity: 'Caridade',
  Causes: 'Causas',
  Community: 'Comunidade',
  Concerts: 'Concertos',
  Music: 'Música',
  Conferences: 'Conferências',
  Expos: 'Exposições',
  Fashion: 'Moda',
  Festivals: 'Festivais',
  Film: 'Cinema',
  Media: 'Mídia',
  Food: 'Comida',
  Drink: 'Bebida',
  Health: 'Saúde',
  Hobbies: 'Hobbies',
  Nightlife: 'Vida noturna',
  Other: 'Outro',
  Performing: 'Artes do',
  arts: 'espetáculo',
  Seasonal: 'Sazonal',
  Holiday: 'Férias',
  Sports: 'Esportes',
  Fitness: 'Fitness',
  Travel: 'Viagem',
  Outdoor: 'Ar livre',
  Career: 'Carreira',
  Design: 'Design',
  Educators: 'Educadores',
  Environment: 'Meio ambiente',
  Sustainability: 'Sustentabilidade',
  Finance: 'Finanças',
  Investment: 'Investimento',
  Non: 'Sem',
  Profit: 'Lucro',
  NGOs: 'ONGs',
  Real: 'Real',
  Estate: 'Imobiliária',
  Sales: 'Vendas',
  Marketing: 'Marketing',
  Startups: 'Startups',
  Small: 'Pequenas',
  Animal: 'Animal',
  Welfare: 'Bem-estar',
  Art: 'Arte',
  Auctions: 'Leilões',
  Benefit: 'Benefício',
  Walks: 'Caminhadas',
  Disaster: 'Desastre',
  Relief: 'Ajuda',
  Donor: 'Doador',
  Recognition: 'Reconhecimento',
  Educational: 'Educacionais',
  Fundraising: 'Captação de recursos',
  Homeless: 'Sem-teto',
  shelters: 'Abrigos',
  Human: 'Humano',
  Rights: 'Direitos',
  International: 'Internacional',
  Aid: 'Ajuda',
  Marches: 'Marchas',
  Poverty: 'Pobreza',
  Rallies: 'Manifestações',
  Demonstrations: 'Demonstrações',
  Ethnic: 'Étnico',
  Fairs: 'Feiras',
  Farmers: 'Agricultores',
  markets: 'Mercados',
  Fetes: 'Festas',
  Fun: 'Diversão',
  runs: 'Corridas',
  Heritage: 'Herança',
  Historic: 'Histórico',
  Language: 'Idioma',
  LGBTQ: 'LGBTQ',
  Nationality: 'Nacionalidade',
  Parades: 'Desfiles',
  Protests: 'Protestos',
  Religious: 'Religioso',
  Renaissance: 'Renascença',
  Spiritual: 'Espiritual',
  Town: 'Cidade',
  hall: 'salão',
  meetings: 'reuniões',
  Acoustic: 'Acústico',
  Americana: 'Americana',
  Bluegrass: 'Bluegrass',
  Blues: 'Blues',
  Jazz: 'Jazz',
  Classical: 'Clássica',
  Cultural: 'Cultural',
  Dance: 'Dança',
  EDM: 'Música eletrônica',
  Electronic: 'Eletrônica',
  Experimental: 'Experimental',
  Folk: 'Folk',
  Hip: 'Hip',
  Hop: 'Hop',
  Rap: 'Rap',
  Indie: 'Indie',
  Latin: 'Latina',
  Metal: 'Metal',
  Opera: 'Ópera',
  Pop: 'Pop',
  Psychedelic: 'Psicodélico',
  Reggae: 'Reggae',
  Rock: 'Rock',
  Singer: 'Cantor',
  Songwriter: 'Compositor',
  Top: 'Topo',
  World: 'Mundo',
  Colloquium: 'Colóquio',
  Conference: 'Conferência',
  Congress: 'Congresso',
  Convention: 'Convenção',
  Demonstration: 'Demonstração',
  Exhibition: 'Exibição',
  Forum: 'Fórum',
  Meeting: 'Reunião',
  Presentation: 'Apresentação',
  Round: 'Redonda',
  table: 'mesa',
  Seminar: 'Seminário',
  Symposium: 'Simpósio',
  Workshop: 'Oficina',
  Award: 'Prêmio',
  Ceremonies: 'Cerimônias',
  Screening: 'Exibição',
  Movie: 'Filme',
  Premiere: 'Estréia',
  Television: 'Televisão',
  Video: 'Vídeo',
  Game: 'Jogo',
  Education: 'Educação',
  Industry: 'Indústria',
  Science: 'Ciência',
  Technology: 'Tecnologia',
  Barbecue: 'Churrasco',
  Breakfast: 'Café da manhã',
  Brunch: 'Brunch',
  Buffet: 'Buffet',
  Cocktail: 'Coquetel',
  Mixology: 'Mixologia',
  Culinary: 'Culinária',
  workshops: 'oficinas',
  Dessert: 'Sobremesa',
  Pastry: 'Padaria',
  Tastings: 'Degustações',
  Dinner: 'Jantar',
  Farm: 'Fazenda',
  to: 'para',
  Table: 'mesa',
  Dining: 'Jantar',
  Gourmet: 'Gourmet',
  Markets: 'Mercados',
  Lunch: 'Almoço',
  Picnic: 'Piquenique',
  Wine: 'Vinho',
  Beer: 'Cerveja',
  Adult: 'Adulto',
  Anime: 'Anime',
  Comics: 'Quadrinhos',
  Book: 'Livro',
  Books: 'Livros',
  Collecting: 'Coleção',
  Cooking: 'Cozinha',
  DIY: 'Faça você mesmo',
  Crafts: 'Artesanato',
  Drawing: 'Desenho',
  Painting: 'Pintura',
  Gaming: 'Jogos',
  Gardening: 'Jardinagem',
  Home: 'Casa',
  Improvement: 'Melhoria',
  Knitting: 'Tricô',
  Photography: 'Fotografia',
  Reading: 'Leitura',
  Sewing: 'Costura',
  Writing: 'Escrita',
  Bar: 'Bar',
  Crawls: 'Rastros',
  Bingo: 'Bingo',
  Cabaret: 'Cabaré',
  Casino: 'Cassino',
  Comedy: 'Comédia',
  Clubs: 'Clubes',
  Parties: 'Festas',
  Dancehall: 'Dancehall',
  Drinks: 'Bebidas',
  Karaoke: 'Karaokê',
  Nightclubs: 'Casas noturnas',
  Open: 'Aberto',
  Mic: 'Microfone',
  Pubs: 'Bares',
  Raves: 'Raves',
  Medical: 'Médico',
  Spa: 'Spa',
  Yoga: 'Yoga',
  Circus: 'Circo',
  Exhibitions: 'Exibições',
  Magic: 'Mágica',
  Musicals: 'Musicais',
  Plays: 'Peças teatrais',
  Theater: 'Teatro',
  Christmas: 'Natal',
  Easter: 'Páscoa',
  Halloween: 'Halloween',
  Hanukkah: 'Hanukkah',
  New: 'Ano Novo',
  Year: 'Ano',
  Eve: 'Véspera',
  Thanksgiving: 'Dia de Ação de Graças',
  Archery: 'Arco e flecha',
  Baseball: 'Beisebol',
  Basketball: 'Basquete',
  Camping: 'Acampamento',
  Camps: 'Acampamentos',
  Cars: 'Carros',
  Cheer: 'Torcida',
  Climbing: 'Escalada',
  Cycling: 'Ciclismo',
  Diving: 'Mergulho',
  Exercise: 'Exercício',
  Fishing: 'Pesca',
  Football: 'Futebol',
  Golf: 'Golfe',
  Hiking: 'Caminhada',
  Hockey: 'Hóquei',
  Hunting: 'Caça',
  Jogging: 'Corrida',
  Lacrosse: 'Lacrosse',
  Motorsports: 'Esportes a motor',
  Obstacles: 'Obstáculos',
  Parachuting: 'Paraquedismo',
  Rally: 'Rali',
  Rugby: 'Rúgbi',
  Running: 'Corrida',
  Skating: 'Patinação',
  Skiing: 'Esqui',
  Snow: 'Neve',
  Snowboarding: 'Snowboard',
  Soccer: 'Futebol',
  Softball: 'Softbol',
  Sport: 'Esporte',
  Surfing: 'Surfe',
  Swimming: 'Natação',
  Water: 'Água',
  Tennis: 'Tênis',
  Volleyball: 'Vôlei',
  Walking: 'Caminhada',
  Weightlifting: 'Levantamento de peso',
  Windsurfing: 'Windsurf',
  Wrestling: 'Luta',
  Cruise: 'Cruzeiro',
  Expeditions: 'Expedições',
  Museum: 'Museu',
  visit: 'visita',
  Road: 'Estrada',
  Trips: 'Viagens',
  tours: 'Tours',
  Rp_request: 'te enviou pedido para ser o PromoBooster do evento dele.',
  warning_message: 'Por favor, pressione "adicionar" primeiro para salvar seu ticket e continuar',
  correct_date_tooltip: 'A data de início selecionada para o seu evento já passou.',
  step1:
    'En este paso, encontrará las estadísticas de ventas del evento y la lista de asistentes al evento.',
  step2: 'En este paso, puede editar los detalles del evento.',
  step3: 'En este paso, puede editar las fechas y horas del evento.',
  step4:
    'En este paso, puede agregar nuevos boletos para su evento o editar los boletos existentes.',
  step5: 'En este paso, puede publicar o archivar su evento.',
  step6:
    'En este paso, encontrará la vista del evento para clientes en la vista de escritorio o móvil.',
  step7: 'En este paso, encontrará una descripción general de los detalles de su evento.',
  step8: 'Neste passo, você pode obter informações sobre o evento.',
  step9: 'En este paso, puede agregar códigos promocionales para los boletos.',
  step10:
    'En este paso, puede gestionar los PromoBooster de su evento, crear un grupo de PromoBooster o enviar solicitudes para que el PromoBooster sea PromoBooster de su evento.',
  step11: 'En este paso, encontrará la lista de los asistentes.',
  total_sold_tooltip: 'Este é o preço total de venda para os ingressos do evento.',
  sold_quantity_tooltip: 'Esta é a quantidade total de ingressos vendidos para o evento.',
  link_tooltip: 'Este é o link do evento para comprar seus ingressos.',
  table_tooltip: 'Aqui está a tabela onde você encontrará a lista dos participantes deste evento.',
  create_event_button_tooltip: 'Clique aqui para criar um novo evento.',
  events_table_tooltip:
    'Esta é a tabela de eventos onde você pode encontrar os detalhes dos seus eventos.',
  You_have_no_organisations: 'Você não tem organizações',
  alert_orga_title: 'Você precisa criar uma organização para poder criar um evento depois.',
  Configurate_here: 'Configure aqui',
  legal_verification: 'Verificação legal da sua conta',
  alert_stripe_configuration_title:
    'Por favor, complete suas informações legais para ativar sua conta e poder vender seus ingressos.',
  Bank_balance: 'Saldo bancário',
  View_my_Stripe_account: 'Ver minha conta Stripe',
  Your_balance: 'Seu saldo',
  Add_new_card: 'Adicionar novo cartão',
  Name_on_card: 'Nome no cartão',
  Card_number: 'Número do cartão',
  Expiration_date: 'Data de validade',
  QRcode_tooltip: 'Clique aqui para personalizar e baixar o código QR do seu evento',
  File_Type: 'Tipo de arquivo',
  Size: 'Tamanho',
  Color: 'Cor',
  Background_Color: 'Cor de fundo',
  Download_QR_Code: 'Baixar código QR',
  Download_Invoice: 'Baixar fatura',
  Close: 'Fechar',
  Customize_QR_Code: 'Personalizar código QR',
  no_users_yet: 'Nenhum usuário ainda',
  plz_invite_users: 'Por favor, convide usuários',
  invite_organizer: 'Convidar organizador',
  invite: 'convidar',
  invite_owners: 'convidar proprietários',
  register: 'Registrar',
  password: 'Senha',
  confirm_password: 'Confirmar Senha',
  email_adress: 'Endereço de Email',
  division_agent: 'Agente',
  division_promoter: 'PromoBooster',
  iam_owner: 'Eu sou um Proprietário',
  iam_owner_desc: 'Gerencie suas organizações, restaurantes e eventos',
  iam_amb: 'Eu sou um Embaixador',
  iam_amb_desc: 'Promova organizações e ganhe comissões',
  iam_ref: 'Eu sou um PromoBooster',
  iam_ref_desc: 'Indique eventos e ganhe comissões',
  start_free: 'Começar Grátis',
  no_credit_card: 'Nenhum Cartão de Crédito Necessário',
  have_account: 'Já tem uma conta?',
  by_register_1: 'Ao me registrar, concordo com os ',
  by_register_2: 'Termos de Serviço',
  by_register_3: ' e a ',
  by_register_4: 'Política de Privacidade do Instadrink.',
  manager_better_register: 'Gerencie eventos de forma mais eficiente com o Instadrink',
  referral: 'PromoBooster',
  first_name_required: 'Nome é obrigatório',
  first_name_short: 'Nome é muito curto - deve ter no mínimo 3 caracteres.',
  first_name_long: 'Nome é muito longo - deve ter no máximo 50 caracteres.',
  first_name_alpha: 'Nome só pode conter caracteres alfabéticos.',
  last_name_required: 'Sobrenome é obrigatório',
  last_name_short: 'Sobrenome é muito curto - deve ter no mínimo 3 caracteres.',
  last_name_long: 'Sobrenome é muito longo - deve ter no máximo 50 caracteres.',
  last_name_alpha: 'Sobrenome só pode conter caracteres alfabéticos.',
  email_invalid: 'O Email deve ser um endereço de email válido',
  email_required: 'Email é obrigatório',
  password_required: 'Senha é obrigatória',
  password_short: 'Senha é muito curta - deve ter no mínimo 8 caracteres.',
  password_alpha: 'Senha só pode conter letras latinas.',
  password_uppercase: 'Senha deve conter pelo menos uma letra maiúscula.',
  password_number: 'Senha deve conter um número.',
  confirm_password_match: 'As senhas devem ser iguais',
  confirm_password_required: 'Confirmar Senha é obrigatório',
  division_required: 'Divisão é obrigatória',
  division_invalid: 'Divisão deve ser PromoBooster ou Agente',
  role_required: 'Função é obrigatória',
  role_invalid_promoter: 'Função inválida para PromoBooster',
  role_invalid_agent: 'Função inválida para Agente',
  login: 'Entrar',
  fr: 'Français',
  en: 'English',
  es: 'Espagnol',
  pt: 'Portugais',
  lang: 'Langue',
  creating_account: 'Criação de Conta em Andamento',
  creating_account_desc: 'Finalizando a Configuração da Sua Conta',
  creating_account_info:
    'Sua conta está sendo configurada. Por favor, permaneça nesta página até que o processo esteja completo. Agradecemos a sua paciência!',
  accept_invitation_info:
    'Por favor, note que, após aceitar o convite abaixo, você receberá um e-mail de verificação. Após a verificação, você poderá completar a configuração da sua conta, incluindo as opções para alterar seu nome e idioma preferido.',
  verify_email: 'Verifique Seu Email',
  verify_email_desc: 'Conta Criada com Sucesso',
  verify_email_info:
    'Parabéns por configurar sua conta! Por favor, verifique seu e-mail para confirmar sua conta e acessar todas as funcionalidades.',
  wait_verify: 'Por favor, aguarde um momento enquanto verificamos seu e-mail.',
  wait_redirect: 'Não feche esta janela até ser redirecionado para o painel de controle.',
  initialAccountHeader: 'Finalize sua conta Instadrink',
  initialAccountDescription:
    'Bem-vindo de volta! Basta definir sua senha abaixo para finalizar a configuração da sua conta.',
  initialAccountButton: 'Concluir Configuração',
  processingAccountHeader: 'Configurando sua conta...',
  processingAccountDescription:
    'Por favor, aguarde um momento enquanto confirmamos seus detalhes e protegemos sua conta.',
  processingAccountButton: 'Concluindo...',
  successAccountHeader: 'Sucesso! Conta Pronta',
  successAccountDescription:
    'Sua conta foi configurada com sucesso e você está sendo conectado. Você será redirecionado para o painel em breve.',
  successAccountButton: 'Redirecionando...',
  errorAccountHeader: 'Algo deu errado',
  errorAccountDescription:
    'Ocorreu um problema com a configuração da sua conta. O link pode ser inválido ou ter expirado, ou o endereço de e-mail pode estar incorreto.',
  errorAccountButton: 'Tentar Novamente',
  login_welcome: 'Olá, Bem-vindo',
  login_connect: 'Conecte-se ao Instadrink',
  login_type: 'Digite seus detalhes abaixo.',
  login_no_account: 'Não tem uma conta?',
  login_register: 'Inscrever-se',
  owner_total_part: 'Esta é a participação do proprietário na venda de ingressos para o evento.',
  my_share: 'Minha parte',
  Remember_password: 'Lembrar senha',
  forgot_password: 'Esqueceu sua senha?',
  create_your_first_organization: 'Crie sua primeira organização',
  create_organization_step_description:
    'Para começar a gerenciar seus eventos, crie sua primeira organização. Você pode criar quantas organizações quiser depois.',
  create_your_first_event: 'Crie seu primeiro evento',
  create_event_step_description:
    'Agora que você criou sua primeira organização, é hora de criar brevemente seu primeiro evento. Você pode editá-lo detalhadamente mais tarde.',
  congratulations: 'Parabéns!',
  last_step_description:
    'Agora você pode navegar até a lista de eventos e continuar criando seu evento.',
  create_your_first_restaurant: 'Crie seu primeiro restaurante',
  create_restaurant_step_description:
    'Para começar a gerenciar suas mesas, crie seu primeiro restaurante. Você pode criar quantos restaurantes quiser depois.',
  create_restaurant_last_step_description:
    'Agora você pode navegar até a lista de restaurantes e continuar criando suas mesas.',
  Logout: 'Sair',
  Settings: 'Configurações',
  create_store: 'Criar restaurante',
  add_category: 'Adicionar categoria',
  select_store: 'Selecionar um restaurante',
  add_new_product: 'Adicionar novo produto',
  add_new_category: 'Adicionar nova categoria',
  products: 'Produtos',
  categories: 'Categorias',
  unavailable: 'Indisponível',
  composite: 'Composto',
  alcoholic: 'Alcoólico',
  Available: 'Disponível',
  Description: 'Descrição',
  simple: 'Simples',
  search_product: 'Buscar produto...',
  min_price: 'Preço mínimo',
  max_price: 'Preço máximo',
  add_product: 'Adicionar produto',
  edit_product: 'Editar produto',
  product_details: 'Detalhes do produto',
  composition_items: 'Itens de composição',
  caption: 'Legenda',
  extra: 'Extra',
  max_quantity: 'Quantidade máxima',
  add_composition: 'Adicionar composição',
  items: 'Itens',
  item_name: 'Nome do item',
  add_item: 'Adicionar item',
  preparation_time: 'Tempo de preparo',
  Orders: 'Pedidos',
  current_orders: 'Pedidos Atuais',
  store: 'Restaurante',
  no_stores_message: 'Você não possui restaurantes para ver pedidos, por favor crie uma primeiro',
  Pending: 'Pendente',
  in_preparation: 'Em Preparação',
  Preparation: 'Preparação',
  order_is_ready: 'Pedido pronto',
  finish_order: 'Finalizar pedido',
  total: 'Total',
  order_of: 'Pedido de',
  Contact: 'Contato',
  reservations_shares: 'Compartilhamento de reservas',
  client_name: 'Nome do cliente',
  total_amount: 'Valor total',
  owner_share: 'Participação do proprietário',
  instadrink_share: 'Participação do Instadrink',
  rp_share: 'Participação do PromoBooster',
  ambassador_share: 'Participação do embaixador',
  shares_distribution: 'Distribuição de ações',
  group_name: 'Nome do grupo',
  group_members: 'Membros do grupo',
  created_by: 'Criado por',
  leave_group: 'Sair do grupo',
  with_product: 'Com produto',
  all_categories: 'Todas as categorias',
  inclusives: 'Inclusivos',
  closing_Time: 'Horário de fechamento',
  open: 'Aberto',
  Rush_hour: 'Hora de pico',
  preview_store: 'Visualização do restaurante',
  Delete_account: 'Excluir conta',
  Are_you_sure_you_want_to_delete_your_account: 'Tem certeza de que deseja excluir sua conta',
  thanks_join_invite: 'Obrigado por se juntar a nois. Seja bem-vindo ao Instadrink!',
  delete_account_page_message:
    'Ao optar por excluir sua conta, você está escolhendo a remoção permanente de todos os seus dados de nosso sistema.',
  account_created_check_email:
    'Conta criada com sucesso. Verifique seu e-mail para confirmar sua conta e acessar todas as funcionalidades.',
  verify_before_expire: 'Verificar antes de expirar',
  if_mistake_contact_instadrink: 'Se houver um erro, entre em contato com o Instadrink',
  invitation_rejected: 'Convite rejeitado',
  invite_owner: 'Eu gostaria de convidar um proprietário',
  invite_referrer: 'Eu gostaria de convidar um PromoBooster',
  invite_ambassador: 'Eu gostaria de convidar um ambassador',
  invite_more_users: 'Convidar mais usuários',
  your_organisation_staff: 'Seu pessoal da organização',
  inv_org_desc: 'O organizador do seu evento',
  inv_bnc_desc: 'O controlador de acesso do seu evento',
  invite_more_staff: 'Convidar mais personal',
  since: 'Desde',
  duplicate_event: 'Duplicar evento',
  duplicate: 'Duplicar',
  reservations: 'Reservas',
  multiple_persons: 'Várias pessoas',
  persons_number: 'Número de pessoas',
  vatRate: 'Taxa do IVA',
  price_TTC: 'Preço com impostos',
  Occupation: 'Ocupação',
  priceHt: 'Preço sem IVA',
  promobooster_mode: 'Modo Promobooster',
  Amount: 'Montante',
  Percentage: 'Percentagem',
  include_owners: 'incluir proprietários',
   add_anonymous_promoter: 'Adicionar SpyBooster', 
  Confirm_Name: 'Confirmar nome',
  Add_Anonymous_Name: 'Adicionar SpyBooster',
  ask_for_invoice: 'pedir fatura',
  add_invoice_button: 'Adicione  ',
  enter_valid_invoice: 'Introduza uma fatura válida',  
  Compan_Name_Last_Name :'Empresa/Nome/Apelido',
    tva :'TVA',
  adresse :'Endereço',
  postal_code :'Código Postal', 
  country :'País',
  additional_information :'Informações adicionais', 
  invoice_information :'Cobrança',
  know_from_invoice :'Que informação exige da sua fatura?',
  add_invoice: 'Adicionar novo campo',
  active_invoice: 'Ative a fatura',
  actived_invoice: 'A fatura está ativada', 
  preview_your_share: 'Visualizar Distribuição  ',
  Number_of_event: 'Número do evento', 
  time_of_creation: 'Criar em', 
  Owner_details: 'Detalhes do proprietário', 

};

export default pt;
