// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to instadrinkpro@gmail.com.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  create_event: 'Create an event',
  label_Rows_Per_Page: 'Rows per page',
  created_At: 'Created at',
  location: 'Location',
  title: 'title',
  events: 'Events',
  archived: 'Archived',
  canceled: 'canceled',
  Start_Date: 'Event start date',
  End_Date: 'Event end date',
  organisation: 'Organisation',
  organisations: 'Organisations',
  published_events: 'Published events',
  Add_event: 'Add Event',
  event_name: 'Event Name',
  summary: 'Summary',
  cancel: 'Cancel',
  add: 'Add',
  view_event: 'View Event ',
  event_title: 'Event Title',
  cover: 'Cover',
  cover_image: 'Cover Image',
  category: 'Category',
  sub_category: 'Sub Category',
  status: 'Status',
  listed: 'Listed',
  online_event: 'Online Event',
  tags: 'Tags',
  address: 'Address',
  city: 'City',
  capacity: 'Capacity',
  save: 'Save',
  Start_Time: 'Event start Time',
  End_Time: 'Event end Time',
  timezone: 'Timezone',
  Save_Changes: 'Save Changes',
  Quantity: 'Quantity',
  Initial_Quantity: 'Available Quantity',
  Max_Quantity: 'Max per order',
  price: 'Price',
  free: 'Free',
  Sell_Time_Limit: 'Sell Time Limit',
  delete: 'Delete',
  Edit: 'Edit',
  Add_Ticket: 'Add Ticket',
  Sell_limit: 'Sell limit',
  start: 'Start',
  end: 'End',
  Access_Time_Limit: 'Event access time',
  Price_Quantity: 'Price and Quantity',
  Access_limit: 'Access limit',
  name: 'Name',
  Your_event_is_already_published_on_this_link: 'Your event is already published on this link',
  archive: 'Archive',
  Event_Dashboard: 'Event Dashboard',
  Preview_Event: 'Preview Event',
  publish: 'Publish',
  Email_Message: 'Text of the email sent to your event participants',
  Ticket_Message: 'Ticket Message',
  include: 'Include',
  required: 'Required',
  Gender: 'Gender',
  Birthday: 'Birthday',
  HomePhone: 'Home Phone',
  yourEventIs: 'Your event is ',
  date: 'Date',
  events_catering: 'Event and Catering',
  events_catering_description:
    'ID is a platform that allows your customers to buy tickets to attend your events, as well as order their food and drinks digitally at your establishment',
  create_digital_exp: 'Create your digital experience',
  create_digital_exp_description:
    'Customers interact with your brand, your way; you control your digital presence to maximize revenue and profitability.',
  digital_ticketing: 'DIGITAL TICKETING',
  digital_ticketing_title: 'Add your events and sell tickets on our platform.',
  digital_ticketing_description:
    'Each of your clients wishing to participate can click on a link or scan a QR code on the digital or physical flyer of your event; this will direct them to the ID platform where they can select and pay for their ticket.',
  more_questions: 'Do you still have questions?',
  more_questions_description: 'Please describe your case to receive the most accurate advice.',
  who_are_we: {
    who: 'Who',
    are: 'are',
    we: 'we',
    work: 'Working together for',
    futur: 'a better future in events and catering',
  },
  what_is_id: {
    title: 'What is ID?',
    description:
      'ID is a platform that allows your customers to buy tickets to attend your events, as well as order their food and drinks digitally at your establishment.',
  },
  about: {
    vision: 'Our vision: To offer the perfect platform for a better future in events and catering.',
    dream_team: 'DREAM TEAM',
    dream_team_description:
      'D will provide you with assistance if you encounter any problems. Our support team will respond .A good team is the key. Within a day, and we also have detailed documentation.',
  },
  testimonials: {
    title: 'TESTIMONIALS',
    who: 'Who likes',
    service: ' our service?',
    description:
      "Our goal is to create the perfect platform that will satisfy all our customers in events and catering. That's why we are constantly working on our services to improve them every day by listening to the valuable suggestions that our users share with us.",
  },
  Min_Quantity: 'Min per order',
  save_continue: 'Save and continue',
  continue_later: "Don't save and continue later",
  Link_to_buy_tickets_for_your_event: 'Link to buy tickets for your event',
  back: 'Back',
  Complete_the_details_of_the_event_to_be_able_to_publish_it:
    'Complete the details of the event to be able to publish it',
  publish_the_event: 'Publish the event',
  view: 'View',
  ready_events: 'Ready events',
  drafted_events: 'Drafted events',
  archived_events: 'Archived events',
  canceled_events: 'canceled events',
  no_events: 'No events',
  my_events: 'My events',
  total_sold: 'Total revenue',
  sold_quantity: 'Number of tickets sold',
  event_link: 'Event link',
  sale_date: 'Sale date',
  validated: 'Validated',
  confirmed: 'Confirmed',
  reimbursed: 'Reimbursed',
  About: 'About',
  Name: 'Name',
  promoters: 'RPs',
  add_promoter: 'Add PromoBooster',
  choose_promoter: 'Choose PromoBooster',
  remove: 'Remove',
  min_followers: 'Minimun followers',
  Dashboard: 'Dashboard',
  General: 'General',
  Preview: 'Preview',
  required_information: 'Required information',
  promo_code: 'Promo codes',
  overview: 'Overview',
  preview_your_event: 'Preview your event',
  Owner: 'Owner',
  create_org: 'Create an organisation',
  Account: 'Account',
  account_settings: 'Account settings',
  Allowed_format: 'Allowed formats',
  max_size: 'max size of',
  billing: 'billing',
  Email_Address: 'Email address',
  Phone_Number: 'Phone number',
  Country: 'Country',
  State_Region: 'State/Region',
  Zip_Code: 'Zip/Code',
  discount: 'Discount',
  maximum_number_of_sold_tickets: 'maximum number of sold tickets',
  Featured_tickets: 'Featured tickets',
  No_promo_code_available: 'No promo code available',
  add_promo_code: 'Add promo code',
  This_code_is_already_used: 'This code is already used!',
  discount_amount: 'Discount amount',
  advanced_options: 'Advanced options',
  all_tickets: 'All tickets',
  specific_tickets: 'Specific tickets',
  Legal_info: 'Legal information',
  Followers: 'Followers',
  Total_events: 'Total events',
  Recommended: 'Recommended',
  company_name: 'Company name',
  company_number: 'Company number',
  Website: 'Website',
  Cover: 'Cover',
  add_organisation: 'Add organisation',
  create_user: 'create user',
  users: 'users',
  firstname: 'First Name',
  lastname: 'Last Name',
  role: 'Role',
  access: 'access',
  events_permissions: 'Events permissions',
  organisations_permissions: 'Organisations permissions',
  Yes: 'Yes',
  No: 'No',
  Permissions: 'Permissions',
  Editing: 'Editing',
  Roles: 'Roles',
  Search: 'Search',
  Event_stats: 'Event stats',
  sales: 'sales',
  Number_of_purchase: 'Number of purchases',
  Link: 'Link',
  sold: 'sold',
  available: 'available',
  Tickets_status: 'Tickets status',
  There_is_no_tickets_status_for_this_event: 'There is no tickets status for this event',
  promoter: 'PromoBooster',
  invite_user: 'Invite user',
  published: 'Published',
  ready: 'Ready',
  draft: 'Draft',
  initial: 'initial',
  upcoming: 'upcoming',
  ongoing: 'ongoing',
  past: 'past',
  Organisation: 'Organisation',
  Bio: 'Bio',
  Type: 'Type',
  Evenementiel: 'Events',
  Restaurant: 'Restaurant',
  Email: 'Email',
  Rajoutez_une_image_de_couverture_pour_votre_événement: 'Add a cover image for your event',
  Upload_photo: 'Upload photo',
  Update_photo: 'Update photo',
  organisation_users: 'Organisation users',
  Agent_liés_à_lorganisation: 'Agents related to the organization',
  Agents_en_attente_de_confirmation: 'Agents awaiting confirmation',
  Instadrink_admin: 'Instadrink Admin',
  create_role: 'Create a role',
  Predefined_roles: 'Predefined roles',
  Custom_roles: 'Custom roles',
  Agents: 'Agents',
  Organizer: 'Organizer',
  Bouncer: 'Bouncer',
  Ambassador: 'Ambassador',
  promotion_groups: 'PromoBoosters groups',
  Dates: 'Dates',
  Tickets: 'Tickets',
  Messages: 'Messages',
  Promotion: 'Promotion',
  Participants: 'Participants',
  Client: 'Client',
  description_placeholder:
    'Describe to your future participants the exceptional atmosphere that will prevail at your event.',
  Tickets_collection: 'Tickets collection',
  Stop_sell_time_is_at: 'Stop sell time is at',
  drag_and_drop: 'drag and drop to change the order',
  delete_ticket_confirmation: 'Are you sure you want to delete this ticket?',
  Access_Time_Thresholdt: 'Event access end time',
  order: 'order',
  Créez_les_tickets_pour_votre_événement: 'Create tickets for your event',
  stop_sell_time_error_message:
    'End date must be later than start date and not exceed the stop sell date',
  Reset: 'Reset',
  send_invitation: 'Send invitation',
  Tickets_quantity_error_message: 'places are remaining',
  Write_something_awesome: 'Write something awesome...',
  Code: 'Code',
  delete_promocode_confirm_message: 'Are you sure you want to delete this code?',
  Activate_promotion: 'Activate promotion',
  Desactivate_promotion: 'Disable promotion',
  Configure_RP_offer: 'Configure the PromoBooster offer',
  promotion_status: 'promotion status',
  TooltipTextForPromotionGroups:
    'In this section you can create and manage Resource Persons (RPs) groups for your events',
  TooltipTextForEventLink: 'Publish your event to get your event link',
  TooltipTextForPromotionGroupsForReferral:
    'In this section, you will find the PromoBooster groups that you are a member of, and you can leave the group whenever you want.',
  confirm_delete_promoter: 'Are you sure you want to delete this PromoBooster?',
  Preview_promoter_link: "Preview PromoBooster's link",
  Locked: 'Locked',
  pending: 'pending',
  accepted: 'accepted',
  rejected: 'rejected',
  Publish_my_event_now: 'Publish my event now',
  publish_later: 'Publish later',
  Your_event_is_still_draft:
    'Your event is still a draft, complete the configuration to publish it',
  Are_you_sure_to_publish_the_event: 'Are you sure to publish the event  ',
  pourcentage: 'pourcentage',
  Rate: 'Rate',
  Message: 'Message',
  submit: 'submit',
  Create_group: 'Create group',
  Groups: 'Groups',
  Edit_group: 'Edit group',
  Search_by_Name: 'Search by name',
  Enter_group_name: 'Enter group name',
  No_promoters_found: 'No promoters found',
  Add_group: 'Add group',
  Number_of_sales: 'Number of sales',
  Turnover: 'Turnover',
  Number_of_clients: 'Number of clients',
  Promotion_requests: 'Promotion requests',
  Requests: 'Requests',
  Notifications: 'Notifications',
  Referral_requests: 'PromoBooster requests',
  no_requests: 'There is no requests',
  request_message: 'sent you a request to invite you to be the PromoBooster of',
  Accept: 'Accept',
  Reject: 'Reject',
  Your_invitation_to: 'Your invitation to',
  on: 'on',
  at: 'at',
  is: 'is',
  promotion_rate: 'promotion rate',
  consigne: 'order',
  Cancel_request: 'Cancel request',
  Send_request: 'Send request',
  No_events: 'Find an event you would like to collaborate as an PromoBooster',
  Search_for_new_events: 'Search for new events',
  Search_for_Events: 'Search for events',
  Sales_stats: 'Sales stats',
  Total_sales: 'Total sales',
  Total_reservation: 'Total reservations',
  Bienvenue_message: 'Welcome to Instadrink, choose your domain',
  second_bienvenu_message:
    'Welcome to Instadrink, select your industry between events and catering.',
  Continue: 'Continue',
  Event: 'Event',
  orga_message: 'Choose the organization under which you will create your event',
  Skip: 'Skip',
  All_steps_completed: 'All steps completed',
  you_have_finished: 'you have finished',
  limit_sell_message: 'Enter a limit date and time for the tickets sale',
  stopSellDate: 'Tickets stop sell date',
  status_tooltip:
    'This status field calculate automatically the the start date and the end date and give you the event status',
  Step: 'Step',
  correct_start_date: 'Correct the past start date',
  Finish: 'Finish',
  event_name_tooltip: 'What is the name of your event?',
  event_summary_tooltip:
    'Give a glimpse of the beautiful surprises awaiting future participants at your event.',
  event_description_tooltip:
    'This time, describe in detail the event that future participants will have the chance to attend.',
  event_cover_tooltip: 'Choose a photo for your event.',
  organisation_tooltip: 'Choose the name of the organization that will host your event.',
  category_tooltip: 'Select a category for your event.',
  subCategory_tooltip: 'Choose a sub-category for your event.',
  Rp_request: 'sent you request to be the PromoBooster of his event',
  warning_message: 'Please press "add" first to save your ticket and continue',
  correct_date_tooltip: 'The selected start date for your event has already passed.',
  step1: 'In this step, you will find the event sales stats and the attendees list for the event.',
  step2: 'In this step, you can edit the event details.',
  step3: 'In this step, you can edit the event dates and times.',
  step4: 'In this step, you can add new tickets for your event or edit the existing tickets.',
  step5: 'In this step, you can publish or archive your event.',
  step6: 'In this step, you find the event view for clients in the desktop view or mobile view.',
  step7: 'In this step, you will find the overview of your event details.',
  step8: 'In this step, you can information about the event.',
  step9: 'In this step, you can add promo codes for the tickets.',
  step10:
    'In this step, you can manage the RPs for your event, create a group of RPs, or send requests for the PromoBooster to be a PromoBooster of your event.',
  step11: 'In this step, you will find the list of attendees.',
  total_sold_tooltip: 'This is the total sale price for the event tickets',
  sold_quantity_tooltip: 'This is the total sale quantity for event the tickets',
  link_tooltip: 'This is the event link to purchase your tickets.',
  table_tooltip: 'Here is the table where you find the list of the attendees for this event.',
  create_event_button_tooltip: 'Click here to create a new event.',
  events_table_tooltip: 'This is the events table where you can find your events details.',
  You_have_no_organisations: 'You have no organisations',
  alert_orga_title: 'You have to create an organisation so you can create an event after.',
  Configurate_here: 'Configurate here',
  legal_verification: 'Legal verification of your account',
  alert_stripe_configuration_title:
    'Please complete your legal information to activate your account and be able to sell your tickets.',
  Bank_balance: 'Bank balance',
  View_my_Stripe_account: 'View my Stripe account',
  Your_balance: 'Your balance',
  Add_new_card: 'Add new card',
  Name_on_card: 'Name on card',
  Card_number: 'Card number',
  Expiration_date: 'Expiration date',
  QRcode_tooltip: 'Click here to customize and download the QR code of your event',
  File_Type: 'File type',
  Size: 'Size',
  Color: 'Color',
  Background_Color: 'Background color',
  Download_QR_Code: 'Download QR Code',
  Download_Invoice: 'Download invoice',
  Close: 'Close',
  Customize_QR_Code: 'Customize QR Code',
  no_users_yet: 'No users yet',
  plz_invite_users: 'Please invite users',
  invite_organizer: 'Invite organizer',
  invite: 'invite',
  settings: 'settings',
  invite_owners: 'invite owners',
  register: 'Register',
  password: 'Password',
  confirm_password: 'Confirm Password',
  email_adress: 'Email Address',
  division_agent: 'Agent',
  division_promoter: 'Promoter',
  iam_owner: 'I am an Owner',
  iam_owner_desc: 'Manage your organizations, restaurants, and events',
  iam_amb: 'I am an Ambassador',
  iam_amb_desc: 'Promote organizations and earn commissions',
  iam_ref: 'I am an PromoBooster',
  iam_ref_desc: 'Refer events and earn commissions',
  start_free: 'Start for Free',
  no_credit_card: 'No Credit Card Required',
  have_account: 'Already have an account?',
  by_register_1: 'By registering, I agree to Instadrink ',
  by_register_2: 'Terms of Service',
  by_register_3: ' and ',
  by_register_4: 'Privacy Policy.',
  manager_better_register: 'Manage events more efficiently with Instadrink',
  referral: 'PromoBooster',
  first_name_required: 'First name required',
  first_name_short: 'First name is too short - should be 3 chars minimum.',
  first_name_long: 'First name is too long - should be 50 chars maximum.',
  first_name_alpha: 'First name can only contain alphabetic characters.',
  last_name_required: 'Last name required',
  last_name_short: 'Last name is too short - should be 3 chars minimum.',
  last_name_long: 'Last name is too long - should be 50 chars maximum.',
  last_name_alpha: 'Last name can only contain alphabetic characters.',
  email_invalid: 'Email must be a valid email address',
  email_required: 'Email is required',
  password_required: 'Password is required',
  password_short: 'Password is too short - should be 8 chars minimum.',
  password_alpha: 'Password can only contain Latin letters.',
  password_uppercase: 'Password must contain at least one uppercase letter.',
  password_number: 'Password must contain a number.',
  confirm_password_match: 'Passwords must match',
  confirm_password_required: 'Confirm Password is required',
  division_required: 'Division is required',
  division_invalid: 'Division must be either Promoter or Agent',
  role_required: 'Role is required',
  role_invalid_promoter: 'Invalid role for Promoter',
  role_invalid_agent: 'Invalid role for Agent',
  login: 'Login',
  fr: 'Français',
  en: 'English',
  es: 'Espagnol',
  pt: 'Portugais',
  lang: 'Language',
  creating_account: 'Account Creation in Progress',
  creating_account_desc: 'Finalizing Your Account Setup',
  creating_account_info:
    'Your account is currently being set up. Please remain on this page until the process is complete. We appreciate your patience!',
  accept_invitation_info:
    'Please note, after accepting the invitation below, you will receive a verification email. Following the verification, you will be able to complete your account setup, including the options to change your name and preferred language.',
  verify_email: 'Verify Your Email',
  verify_email_desc: 'Account Successfully Created',
  account_created_check_email:
    'Account created successfully. Please check your email to verify your account and gain full access.',
  thanks_join_invite: 'Thank you for joining our community!',
  verify_before_expire: 'Verify your mail before it expires',
  verify_email_info:
    'Congratulations on setting up your account! Please check your email inbox to verify your account and gain full access.',
  wait_verify: 'Please wait a moment while we verify your email.',
  wait_redirect: 'Do not close this window until you have been redirected to the dashboard.',
  initialAccountHeader: 'Finalize Your Instadrink Account',
  initialAccountDescription:
    'Welcome back! Just set your password below to finalize your account setup.',
  initialAccountButton: 'Complete Setup',
  processingAccountHeader: 'Setting Up Your Account...',
  processingAccountDescription:
    'Please wait a moment while we confirm your details and secure your account.',
  processingAccountButton: 'Completing...',
  successAccountHeader: 'Success! Account Ready',
  successAccountDescription:
    "Your account has been successfully set up, and you're being signed in. You will be redirected to the dashboard shortly.",
  successAccountButton: 'Redirecting...',
  errorAccountHeader: 'Something Went Wrong',
  errorAccountDescription:
    'There was an issue with your account setup. The link may be invalid or expired, or the email address may be incorrect.',
  errorAccountButton: 'Try Again',
  login_welcome: 'Hi, Welcome',
  login_connect: 'Log in to Instadrink',
  login_type: 'Enter your details below.',
  login_no_account: "Don't have an account?",
  login_register: 'Sign Up',
  owner_total_part: 'This is the owner shares for the event tickets sales.',
  my_share: 'My share',
  Remember_password: 'Remember password',
  forgot_password: 'Forgot your password?',
  create_your_first_organization: 'Create your first organization',
  create_organization_step_description:
    'To start managing your events. Create your first organization. You can create as many organizations as you want later.',
  create_your_first_event: 'Create your first event',
  create_event_step_description:
    "Now that you've created your first organization, it's time to briefly create your first event. You can edit it in detail later.",
  congratulations: 'Congratulations !',
  last_step_description: 'You can now navigate to the events list and continue creating your event',
  create_your_first_restaurant: 'Create your first restaurant',
  create_restaurant_step_description:
    'To start managing your tables. Create your first restaurant. You can create as many restaurants as you want later.',
  create_restaurant_last_step_description:
    'You can now navigate to the restaurant list and continue creating your tables',
  Logout: 'Logout',
  Settings: 'Settings',
  create_store: 'Create store',
  add_category: 'Add Category',
  select_store: 'Select a srore',
  add_new_product: 'Add new product',
  add_new_category: 'Add new category',
  products: 'Products',
  categories: 'Categoies',
  unavailable: 'Unavailable',
  composite: 'Composite',
  alcoholic: 'Alcoholic',
  Available: 'Available',
  Description: 'Description',
  simple: 'Simple',
  search_product: 'Search product...',
  min_price: 'Minimum price',
  max_price: 'Maximum price',
  add_product: 'Add product',
  edit_product: 'Edit product',
  product_details: 'Product details',
  composition_items: 'Composition items',
  caption: 'Caption',
  extra: 'Extra',
  max_quantity: 'Max quantity',
  add_composition: 'Add composition',
  items: 'Items',
  item_name: 'item name',
  add_item: 'Add item',
  preparation_time: 'Preparation Time',
  Orders: 'Orders',
  current_orders: 'Current Orders',
  store: 'Store',
  no_stores_message: 'You have no stores to see orders, please create one first',
  Pending: 'Pending',
  in_preparation: 'In Preparation',
  Preparation: 'Preparation',
  accept_order: 'Accept order',
  order_is_ready: 'Order is ready',
  finish_order: 'Finish order',
  total: 'Total',
  order_of: 'Order of',
  Contact: 'Contact',
  reservations_shares: 'Reservations shares',
  client_name: 'Client name',
  total_amount: 'Total amount',
  owner_share: 'Owner share',
  instadrink_share: 'Instadrink share',
  rp_share: 'PromoBooster share',
  ambassador_share: 'Ambassador share',
  shares_distribution: 'Shares distribution',
  group_name: 'Group name',
  group_members: 'Group members',
  created_by: 'Created by',
  leave_group: 'Leave group',
  with_product: 'With product',
  all_categories: 'All categories',
  inclusives: 'Inclusives',
  closing_Time: 'closing time',
  open: 'Open',
  Rush_hour: 'Rush hour',
  preview_store: 'Preview store',
  Delete_account: 'Delete account',
  Are_you_sure_you_want_to_delete_your_account: 'Are you sure you want to delete your account',
  delete_account_page_message:
    ' By choosing to delete your account, you are opting for the permanent removal of all your data from our system.',
  restaurants: 'Restaurants',
  network: 'Network',
  stores: 'Stores',
  commandes: 'Commandes',
  applications: 'Applications',
  inviteUsers: 'Invite Users',
  inviteAgents: 'Invite Agents',
  inviteStaff: 'Invite Staff',
  invitationsList: 'Invitations list',
  promotersTree: 'Invitations tree',
  promotionGroups: 'PromoBoosters Groups',
  onboarding: 'Onboarding',
  if_mistake_contact_instadrink: 'If you have a mistake, please contact Instadrink',
  invitation_rejected: 'Invitation rejected',
  invite_owner: 'I want to invite a owner',
  invite_referrer: 'I want to invite a PromoBooster',
  invite_ambassador: 'I want to invite an ambassador',
  your_organisation_staff: 'Your organisation staff',
  inv_org_desc: 'The organizer of your event',
  inv_bnc_desc: 'The access controller of your event',
  invite_more_staff: 'Invite more staff',
  since: 'Since',
  duplicate_event: 'Duplicate event',
  duplicate: 'Duplicate',
  reservations: 'Reservations',
  multiple_persons: 'Multiple persons',
  persons_number: 'Number of persons',
  vatRate: 'VAT rate',
  price_TTC: 'Price including tax',
  Occupation: 'Occupation',
  priceHt: 'Price excluding tax',
  promobooster_mode: 'Promobooster mode',
  Amount: 'Amount',
  Percentage: 'Percentage',
  include_owners: 'Include owners',
  add_anonymous_promoter: 'Add SpyBooster', 
  Confirm_Name: 'Confirm name',
  Add_Anonymous_Name: 'Add SpyBooster', 
  ask_for_invoice: 'Ask for invoice',
  add_invoice_button: 'Add  ',
  enter_valid_invoice: 'Please enter a valid invoice',   
  Compan_Name_Last_Name :'Company/Name/Last Name',
  tva :'TVA',
  adresse :'address',
  postal_code :'Postal Code ', 
  country :'Country',
  additional_information :'Additional information', 
  invoice_information :'Billing',
  know_from_invoice :'What information do you require from your invoice?',
  know_from_attendees :'What do you need to know from your attendees?', 
  add_invoice: 'Add new field',
  active_invoice: 'Active the Invoice',
  actived_invoice: ' The Invoice is Activated',
  preview_your_share: 'Preview  distribution ',
  Number_of_event: 'Number of event', 
  time_of_creation: 'Creat at', 
  Owner_details: 'Owner details', 


};

export default en;
